import { createTheme } from '@mui/material/styles';
import checkmarkSvg from '../../icons/CheckMark.svg';
import { CUSTOM_STYLES } from '../../constants';
import '@mui/material/styles';

export interface IPalette {
  white?: Partial<IColors>;
  dark?: Partial<IColors>;
}
export interface ICustomStyles {
  contentPosition?: 'start' | 'center' | 'end';
  isAccordionIconColored?: boolean;
  palette?: IPalette;
  button?: {
    borderRadius?: string;
  };
}
export interface IColors {
  accent: string;
  accentHover: string;
  onAccentColor: string;
  secondaryAccent: string;
  secondaryAccentHover: string;
  onSecondaryAccentColor: string;
  outlinedColor: string;
  outlinedBackgroundColor: string;
  borderColor: string;
  outlinedHoverBackgroundColor: string;
  outlinedHoverBorderColor: string;
  mainColor: string;
  secondaryColor: string;
  errorColor: string;
  mainGreyColor: string;
  secondaryGreyColor: string;
  linkColor: string;
  backgroundColor: string;
  buttonBackgroundColor: string;
  buttonColor: string;
  backgroundSelectedColor: string;
  backgroundDisabledColor: string;
  backgroundHoverColor: string;
  backgroundDarkenedColor: string;
}

export const borderRadius = CUSTOM_STYLES.button?.borderRadius
  ? CUSTOM_STYLES.button?.borderRadius
  : '0px';
export const isAccordionIconColored = CUSTOM_STYLES.isAccordionIconColored
  ? CUSTOM_STYLES.isAccordionIconColored
  : false;
export const contentPosition = CUSTOM_STYLES.contentPosition
  ? CUSTOM_STYLES.contentPosition
  : 'start';

export const defaultColors: { white: IColors; dark: IColors } = {
  white: {
    accent: '#4C6AD4',
    accentHover: '#4560BF',
    onAccentColor: '#fff',
    secondaryAccent: '#ecedf0',
    secondaryAccentHover: '#e6e7e9',
    onSecondaryAccentColor: '#0B1641',
    outlinedColor: 'rgba(0, 0, 0, 0.12)',
    outlinedBackgroundColor: '#fff',
    borderColor: 'rgba(33, 134, 240, 0.5)',
    outlinedHoverBackgroundColor: 'rgba(33, 134, 240, 0.04)',
    outlinedHoverBorderColor: 'rgba(33, 134, 240)',
    mainColor: '#0B1641',
    secondaryColor: '#3c4567',
    errorColor: '#FC545C',
    mainGreyColor: '#858BA0',
    secondaryGreyColor: '#B6BAC6',
    linkColor: '#4c6ad4',
    backgroundColor: '#ffffff',
    buttonBackgroundColor: '#e7e8ec',
    buttonColor: '#fffede',
    backgroundSelectedColor: '#f5f6f8',
    backgroundDisabledColor: '#ecedf0',
    backgroundHoverColor: '#f1f1f4',
    backgroundDarkenedColor: ' #fbfbfc',
  },
  dark: {
    accent: '#4C6AD4',
    accentHover: '#4560BF',
    onAccentColor: '#fff',
    secondaryAccent: '#47423a',
    secondaryAccentHover: '#332f29',
    onSecondaryAccentColor: '#fffede',
    outlinedColor: '#4C6AD4',
    outlinedBackgroundColor: '#1e1c19',
    borderColor: 'rgba(33, 134, 240, 0.5)',
    outlinedHoverBackgroundColor: '#272521',
    outlinedHoverBorderColor: 'rgba(33, 134, 240)',
    mainColor: '#fffede',
    secondaryColor: '#3c4567',
    errorColor: '#FC545C',
    mainGreyColor: '#baac94',
    secondaryGreyColor: '#B6BAC6',
    linkColor: '#444553',
    backgroundColor: '#1e1c19',
    buttonBackgroundColor: '#2d2b26',
    buttonColor: '#fffede',
    backgroundSelectedColor: '#24221e',
    backgroundDisabledColor: '#2a2723',
    backgroundHoverColor: '#201f1b',
    backgroundDarkenedColor: '#103030',
  },
};

export const themeColors: { white: IColors; dark: IColors } = {
  white: { ...defaultColors.white, ...CUSTOM_STYLES?.palette?.white },
  dark: { ...defaultColors.dark },
};

declare module '@mui/material/styles' {
  interface TypographyVariantsOptions {
    customVariants?: Record<'test' | 'test1', React.CSSProperties>;
  }

  interface Palette {
    custom: {
      main: string;
      mainGrey: string;
      backgroundDisabled: string;
      secondaryGrey: string;
      error: string;
      link: string;
      secondary: string;
      background: string;
      button: string;
      buttonBackgroundColor: string;
      backgroundSelected: string;
      /** Главный акцентный цвет, используется для основных кнопок */
      accent: string;
      /** Ховер на акцентный цвет */
      accentHover: string;
      /** Цвет для отрисовки поверх акцента, например текст на кнопке */
      onAccentColor: string;
      /** Вторичный акцент, для менее значимых кнопок */
      secondaryAccent: string;
      /** Ховер на вторичный цвет */
      secondaryAccentHover: string;
      /** Цвет для отрисовки поверх вторичного цвета, например текст на кнопке */
      onSecondaryAccentColor: string;
      /** Цвет текста для контурных кнопок */
      outlinedColor: string;
      /** Цвет фона контурных элементов */
      outlinedBackgroundColor: string;
      /** Цвет конткров, например контурных кнопок */
      borderColor: string;
      /** Цвет ховера фона контурных элементов */
      outlinedHoverBackgroundColor: string;
      /** Цвет ховера контуров */
      outlinedHoverBorderColor: string;
    };
  }

  interface PaletteOptions {
    custom: {
      main: string;
      mainGrey: string;
      backgroundDisabled: string;
      secondaryGrey: string;
      error: string;
      link: string;
      secondary: string;
      background: string;
      button: string;
      buttonBackground: string;
      backgroundSelected: string;
      accent: string;
      accentHover: string;
      onAccentColor: string;
      secondaryAccent: string;
      secondaryAccentHover: string;
      onSecondaryAccentColor: string;
      outlinedColor: string;
      outlinedBackgroundColor: string;
      borderColor: string;
      outlinedHoverBackgroundColor: string;
      outlinedHoverBorderColor: string;
    };
  }
}

export type ThemeColors =
  | 'grey'
  | 'error'
  | 'link'
  | 'secondary'
  | 'background'
  | 'secondary-grey'
  | 'button';

function darkenHex(hex: string, percent: number): string {
  if (hex.startsWith('#')) {
    hex = hex.slice(1);
  }

  let r = parseInt(hex.substr(0, 2), 16);
  let g = parseInt(hex.substr(2, 2), 16);
  let b = parseInt(hex.substr(4, 2), 16);

  r = Math.max(0, Math.min(255, Math.floor(r * (1 - percent / 100))));
  g = Math.max(0, Math.min(255, Math.floor(g * (1 - percent / 100))));
  b = Math.max(0, Math.min(255, Math.floor(b * (1 - percent / 100))));

  const darkenedHex =
    '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();

  return darkenedHex;
}
export const theme = ({
  mainColor,
  secondaryColor,
  mainGreyColor,
  secondaryGreyColor,
  backgroundColor,
  linkColor,
  errorColor,
  backgroundDisabledColor,
  buttonColor,
  backgroundSelectedColor,
  buttonBackgroundColor,
  accent,
  accentHover,
  onAccentColor,
  secondaryAccent,
  secondaryAccentHover,
  onSecondaryAccentColor,
  outlinedColor,
  outlinedBackgroundColor,
  borderColor,
  outlinedHoverBackgroundColor,
  outlinedHoverBorderColor,
}: {
  mainColor: string;
  secondaryColor: string;
  mainGreyColor: string;
  secondaryGreyColor: string;
  backgroundColor: string;
  errorColor: string;
  linkColor: string;
  backgroundDisabledColor: string;
  buttonColor: string;
  backgroundSelectedColor: string;
  buttonBackgroundColor: string;
  accent: string;
  accentHover: string;
  onAccentColor: string;
  secondaryAccent: string;
  secondaryAccentHover: string;
  onSecondaryAccentColor: string;
  outlinedColor: string;
  outlinedBackgroundColor: string;
  borderColor: string;
  outlinedHoverBackgroundColor: string;
  outlinedHoverBorderColor: string;
}) =>
  createTheme({
    palette: {
      primary: {
        main: mainColor,
      },
      error: {
        main: errorColor,
      },
      custom: {
        main: mainColor,
        mainGrey: mainGreyColor,
        secondaryGrey: secondaryGreyColor,
        error: errorColor,
        link: linkColor,
        secondary: secondaryColor,
        background: backgroundColor,
        backgroundDisabled: backgroundDisabledColor,
        button: buttonColor,
        backgroundSelected: backgroundSelectedColor,
        buttonBackground: buttonBackgroundColor,
        accent,
        accentHover,
        onAccentColor,
        secondaryAccent,
        secondaryAccentHover,
        onSecondaryAccentColor,
        outlinedColor,
        outlinedBackgroundColor,
        borderColor,
        outlinedHoverBackgroundColor,
        outlinedHoverBorderColor,
      },
      background: {
        default: backgroundColor,
      },
    },

    typography: {
      fontFamily: 'Inter',
      link: {
        color: accent ? accent : linkColor,
      },
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            '&.MuiButton-root': {
              textTransform: 'none',
            },
            '&.MuiIconButton-root:hover': {
              background: 'none',
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: backgroundColor,
          },
        },
      },
      MuiStepper: {
        styleOverrides: {
          root: {
            '&.custom': {
              '& .MuiStepConnector-line': {
                borderColor: darkenHex(backgroundColor, 5),
              },

              '& .MuiStep-root': {
                '&:first-child': {
                  paddingLeft: 0,
                },
                '&:last-child': {
                  paddingRight: 0,
                },
              },

              '& .MuiSvgIcon-root': {
                color: '#F1F1F4',
                '& .MuiStepIcon-text': {
                  fill: secondaryColor,
                },
                '&.Mui-completed': {
                  color: linkColor,
                  '& .MuiStepIcon-text': {
                    fill: backgroundColor,
                  },
                },
                '&.Mui-active': {
                  color: linkColor,
                  '& .MuiStepIcon-text': {
                    fill: backgroundColor,
                  },
                },
              },

              '& .MuiStepLabel-labelContainer': {
                color: mainGreyColor,
                ' .Mui-completed': {
                  color: mainColor,
                  fontWeight: 400,
                },

                ' .Mui-active': {
                  color: mainColor,
                  fontWeight: 400,
                },
              },
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          FormHelperTextProps: {
            className: 'text-14',
            color: mainGreyColor,
          },
        },
        styleOverrides: {
          root: ({ ownerState }) => ({
            '&.custom': {
              '& .MuiInput-root': {
                padding: 0,

                ...((ownerState.multiline || ownerState.InputProps?.endAdornment) && {
                  border: `1px solid ${backgroundSelectedColor}`,
                  borderRadius: 1,
                }),
                '& .MuiInputBase-input': {
                  ...(!ownerState.multiline &&
                    !ownerState.InputProps?.endAdornment && {
                      border: `1px solid ${backgroundSelectedColor}`,
                      borderRadius: 1,
                    }),
                  borderRadius: 1,
                  minHeight: 42,
                  boxSizing: 'border-box',
                  color: secondaryColor,
                  padding: '1px 1px 1px 16px',
                },
                ...(!ownerState.multiline &&
                  !ownerState.InputProps?.endAdornment && {
                    '&.Mui-focused .MuiInputBase-input': {
                      border: '2px solid #606783',
                      padding: '0px 0px 0px 15px',
                    },
                  }),
                '&.Mui-focused': {
                  borderColor: '#606783 !important',
                  borderWidth: '2px',
                },
                '&.Mui-disabled': { background: backgroundDisabledColor, color: mainGreyColor },
                '&.Mui-disabled .MuiInputBase-input': { border: 'none' },
                '&::before': { display: 'none' },
                '&::after': {
                  display: 'none',
                },
                '& textarea.MuiInput-input': {
                  minHeight: '26px !important',
                  outline: 'none',
                  resize: 'none',
                  fontFamily: 'Inter',
                  maxHeight: 200,
                  overflow: 'auto !important',
                  paddingTop: '4px !important',
                },
                '&.Mui-error .MuiInputBase-input': {
                  borderColor: errorColor,
                },
              },
              '& .MuiFormHelperText-root': {
                marginTop: 8,
              },
              ...(!ownerState.multiline &&
                !ownerState.InputProps?.endAdornment && {
                  '& .Mui-error .MuiInputBase-input': {
                    border: `2px solid ${errorColor}`,
                  },
                }),
              '& .Mui-error': {
                borderColor: errorColor,
                borderWidth: '2px',
              },
            },
          }),
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: 41,
            height: 20,
            padding: 0,
            '& .MuiSwitch-switchBase': {
              padding: 0,
              transitionDuration: '300ms',
              transform: 'translateX(2px) translateY(2px)',
              '&.Mui-checked': {
                transform: 'translateX(23px) translateY(2px)',
                color: backgroundColor,
                '& + .MuiSwitch-track': {
                  backgroundColor: accent,
                  opacity: 1,
                  border: 0,
                },
                '& .MuiSwitch-thumb': {
                  background: backgroundColor,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                  opacity: 0.5,
                },
              },
              '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: `6px solid ${backgroundColor}`,
              },
              '&.Mui-disabled .MuiSwitch-thumb': {
                color: '#000',
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.3,
              },
            },
            '& .MuiSwitch-thumb': {
              boxSizing: 'border-box',
              width: 16,
              height: 16,
              background: '#9DA2B3',
            },
            '& .MuiSwitch-track': {
              border: '1px solid #9DA2B3',
              borderRadius: '10px',
              backgroundColor: backgroundColor,
              opacity: 1,
            },
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            '&.Mui-expanded': {
              margin: 0,
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: () => ({
            '&.custom-select': {
              paddingLeft: 55,
              display: 'block',
              position: 'relative',
            },
            '&.Mui-selected': {
              backgroundColor: `${backgroundSelectedColor} !important`,
              '::after': {
                width: 24,
                height: 24,
                content: '""',
                position: 'absolute',
                left: 24,
                top: '50%',
                transform: 'translateY(-50%)',
                background: `url(${checkmarkSvg})`,
                backgroundSize: 'cover',
              },
            },
          }),
        },
      },

      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            minWidth: 0,
            '&.MuiButton-custom': {
              ...(ownerState.variant === 'custom' &&
                ownerState.endIcon && {
                  paddingRight: 16,
                  '&.Mui-focusVisible': { paddingRight: 14 },
                  '&:active': { paddingRight: 16 },
                }),
              ...(ownerState.variant === 'custom' &&
                ownerState.endIcon &&
                !ownerState.children && {
                  padding: 0,
                  height: 40,
                  width: 40,
                  '&.Mui-focusVisible': { height: 36, width: 36, padding: 0 },
                  '&:active': { height: 40, width: 40, padding: 0 },
                  '& .MuiButton-endIcon': {
                    margin: 0,
                  },
                  '& .MuiButton-startIcon': {
                    margin: 0,
                  },
                }),
              ...(ownerState.variant === 'custom' &&
                ownerState.startIcon && {
                  paddingLeft: 16,
                  '&.Mui-focusVisible': { paddingLeft: 14 },
                  '&:active': { paddingLeft: 16 },
                }),
              ...(ownerState.variant === 'custom' &&
                ownerState.startIcon &&
                !ownerState.children && {
                  padding: 0,
                  height: 40,
                  width: 40,
                  '&.Mui-focusVisible': { height: 36, width: 36, padding: 0 },
                  '&:active': { height: 40, width: 40, padding: 0 },
                  '& .MuiButton-endIcon': {
                    margin: 0,
                  },
                  '& .MuiButton-startIcon': {
                    margin: 0,
                  },
                }),
            },
          }),
          startIcon: {
            margin: 0,
          },
          endIcon: {
            margin: 0,
          },
        },
        variants: [
          {
            props: { variant: 'custom' },
            style: {
              padding: '0 24px',
              fontSize: 14,
              lineHeight: '21px',
              color: onAccentColor ? onAccentColor : buttonColor,
              background: accent ? accent : linkColor,
              borderRadius: borderRadius ? borderRadius : 1,
              transition: 'none',
              height: 40,
              fontWeight: 400,
              boxSizing: 'content-box',
              '&.MuiButtonBase-root:hover': {
                background: accentHover ? accentHover : darkenHex(linkColor, 10),
              },
              '&.Mui-focusVisible': {
                padding: '0 22px',
                height: 36,
                border: `2px solid ${mainColor}`,
              },
              '&:active': {
                padding: '0 24px',
                border: 'none',
                height: 40,
              },
              '&:disabled': {
                opacity: 0.6,
                color: buttonColor,
              },
              '& .MuiButton-endIcon': {
                marginLeft: 8,
              },
              '& .MuiButton-startIcon': {
                marginRight: 8,
              },
            },
          },
          {
            props: { variant: 'custom', color: 'error' },
            style: {
              background: errorColor,
              '&.MuiButtonBase-root:hover': {
                background: darkenHex(errorColor, 10),
              },
            },
          },
          {
            props: { variant: 'custom', color: 'secondary' },
            style: {
              background: secondaryAccent ? secondaryAccent : backgroundDisabledColor,
              color: onSecondaryAccentColor ? onSecondaryAccentColor : mainColor,
              '&.MuiButtonBase-root:hover': {
                background: secondaryAccentHover
                  ? secondaryAccentHover
                  : darkenHex(backgroundColor, 5),
              },
              '& .MuiButton-endIcon > svg': {
                stroke: onSecondaryAccentColor,
              },
            },
          },
          {
            props: { variant: 'custom2' },
            style: {
              borderRadius: 0,
              padding: 2,
              fontSize: 14,
              lineHeight: '21px',
              background: 'none',
              fontWeight: 400,
              color: accent ? accent : linkColor,
              '&.MuiButtonBase-root:hover': {
                background: 'none',
                textDecoration: 'underline',
              },
              '&.Mui-focusVisible': {
                border: `2px solid ${mainColor}`,
                padding: 0,
              },
              '&:active': {
                border: 'none',
                padding: 2,
              },
              '&:disabled': {
                color: linkColor,
                opacity: 0.6,
              },
              '& .MuiButton-startIcon path': {
                fill: accent ? accent : linkColor,
              },
            },
          },
          {
            props: { variant: 'custom3' },
            style: {
              color: accent ? accent : linkColor,
              '& .MuiButton-startIcon path': {
                fill: accent ? accent : linkColor,
              },
            },
          },
          {
            props: { variant: 'contained' },
            style: {
              color: onAccentColor ? onAccentColor : buttonColor,
              background: accent ? accent : linkColor,
              borderRadius: borderRadius ? borderRadius : 1,
              '&.MuiButtonBase-root:hover': {
                background: accentHover ? accentHover : '#3050C5',
              },
            },
          },
          {
            props: { variant: 'outlined' },
            style: {
              color: outlinedColor ? outlinedColor : '#1976d2',
              background: outlinedBackgroundColor ? outlinedBackgroundColor : backgroundColor,
              borderColor: borderColor ? borderColor : 'rgba(25, 118, 210, 0.5)',
              borderRadius: borderRadius ? borderRadius : 1,
              '&.MuiButtonBase-root:hover': {
                background: outlinedHoverBackgroundColor
                  ? outlinedHoverBackgroundColor
                  : 'rgba(25, 118, 210, 0.04)',
                borderColor: outlinedHoverBorderColor
                  ? outlinedHoverBorderColor
                  : 'rgba(25, 118, 210)',
              },
              '&.Mui-disabled': {
                borderColor: 'rgba(0, 0, 0, 0.12)',
                color: mainGreyColor,
              },
            },
          },
        ],
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            '&.Mui-expanded': {
              minHeight: 0,
            },
            '&.MuiButtonBase-root': {
              minHeight: 0,
              maxHeight: '88px',
            },
          },
          expandIconWrapper: {
            padding: '2px',
            borderRadius: '20px',
            backgroundColor: 'transparent',
            transition: '150ms cubic-bezier(0.4, 0, 0.2, 1)',
            '&.MuiAccordionSummary-expandIconWrapper:hover': {
              '&::after': {
                content: '""',
                width: '24px',
                height: '24px',
                borderRadius: '24px',
                backgroundColor: isAccordionIconColored ? accent : 'transparent',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                zIndex: -1,
              },
            },
            '&.MuiAccordionSummary-expandIconWrapper > button > svg': {
              stroke: mainGreyColor,
            },
            '&.MuiAccordionSummary-expandIconWrapper:hover > button > svg': {
              stroke: isAccordionIconColored && onAccentColor,
            },
          },
        },
      },
      MuiLink: {
        variants: [
          {
            props: { variant: 'link' },
            style: {
              color: accent ? accent : linkColor,
            },
          },
        ],
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: mainColor ? mainColor : linkColor,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: mainGreyColor ? mainGreyColor : mainGreyColor,
            '&.Mui-selected': {
              color: mainColor ? mainColor : linkColor,
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            background: mainColor,
            color: onAccentColor ? onAccentColor : buttonColor,
          },
          arrow: {
            color: mainColor,
          },
        },
      },
    },
  });

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    custom: true;
    custom2: true;
    custom3: true;
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsVariantOverrides {
    custom: true;
    custom2: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    link: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    link?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    link: true;
  }
}
