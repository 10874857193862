import { FunctionComponent } from 'react';
import { ReactComponent as AvatarIcon } from '../icons/Avatar.svg';
import { ReactComponent as MailIcon } from '../icons/Mail.svg';
import { ReactComponent as PhoneIcon } from '../icons/Phone.svg';
import { ReactComponent as GroupsIcon } from '../icons/Groups.svg';
import { ReactComponent as OfflineIcon } from '../icons/Exit.svg';

const profileScope = {
  description: 'Просмотр имени, фото и даты рождения',
  icon: AvatarIcon,
};

const emailScope = {
  description: 'Просмотр адреса электронной почты',
  icon: MailIcon,
};

const phoneScope = {
  description: 'Просмотр номера телефона',
  icon: PhoneIcon,
};

const offlineAccessScope = {
  description: 'Приложение получит доступ к вашим данным в любое время',
  icon: OfflineIcon,
};

const accountsScope = {
  description: 'Просмотр аккаунтов социальных сетей, добавленных в профиль',
  icon: GroupsIcon,
};

export const getScopeProps = (
  scope: string,
): { description: string; icon: FunctionComponent } | undefined => {
  switch (scope) {
    case 'profile':
      return profileScope;
    case 'email':
      return emailScope;
    case 'phone':
      return phoneScope;
    case 'offline_access':
      return offlineAccessScope;
    case 'accounts':
      return accountsScope;
    default:
      return undefined;
  }
};
