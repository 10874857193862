import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { App } from './App';
import { ErrorBoundary } from './ErrorBoundary';
import './index.css';
import './fonts.css';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack';
import { CustomSnackbar, ESnackBarVariant } from './components/custom/CustomSnackbar';
import { PROJECT_NAME } from './constants';

declare module 'notistack' {
  interface VariantOverrides {
    customSnackbar: {
      avatar?: string | null;
      snackbarVariant: ESnackBarVariant;
    };
  }
}

document.title = PROJECT_NAME;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ErrorBoundary>
        <SnackbarProvider
          maxSnack={1}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          Components={{
            customSnackbar: CustomSnackbar,
          }}
        >
          <App />
        </SnackbarProvider>
      </ErrorBoundary>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
