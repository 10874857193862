import React, { FC, useState } from 'react';
import styles from './ChooseProvider.module.css';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as ArrowTopIcon } from '../icons/ArrowTop.svg';
import { ReactComponent as CloseIcon } from '../icons/Close.svg';
import { ReactComponent as IdIcon } from '../icons/Id.svg';
import { ReactComponent as InfoIcon } from '../icons/Info.svg';
import {
  MiscProviderType,
  OauthProviderType,
  useCreateProviderMutation,
} from '../redux/services/provider';
import { CreateProvider } from './CreateProvider';
import { CreateProviderByTemplate } from './CreateProviderByTemplate';
import { BACKEND_URL } from '../constants';
import { CreateEthereumProvider } from './CreateEthereumProvider';
import { CreateSmsProvider } from './CreateSmsProvider';
import { CreateKloudProvider } from './CreateKloudProvider';
import { ModalContinueOnly } from './modal/ModalContunueOnly';
import { useParams } from 'react-router-dom-v5-compat';

//PRIVATE REPO
import { Create1CProvider } from './Create1CProvider';
import { CreateIdmProvider } from './CreateIdmProvider';
import { CreateLdapProvider } from './CreateLdapProvider';
import {
  ProviderScope,
  TProviderRule,
  useGetLicensesQuery,
  useGetRulesProvidersQuery,
} from '../redux/services/settings';
import { TProviderItem } from './applications/EditApplicationHeader';
import { CreateEsiaProvider } from './CreateEsiaProvider';
import { CustomTypography } from './custom/CustomTypography';
import { useTheme } from '@mui/material';
//PRIVATE REPO END

type TCreateProvider = {
  isOpen: boolean;
  close: () => void;
  providerType: ProviderScope;
  providersList: TProviderItem[];
};

export type TTemplate = {
  type: OauthProviderType | MiscProviderType;
  name: string;
  pathToAvatar: string;
  scope: ProviderScope;
};

export const ChooseProvider: FC<TCreateProvider> = ({
  isOpen,
  close,
  providerType,
  providersList,
}) => {
  const { clientId = '' } = useParams<{ clientId: string }>();
  const theme = useTheme();
  const [createProvider] = useCreateProviderMutation();
  const ldapProviderAvatar = 'public/images/provider/ldap.svg';
  const aldProProviderAvatar = 'public/images/provider/ald-pro.png';
  const _1CProviderAvatar = 'public/images/provider/1c.svg';
  const ethereumProviderAvatar = 'public/images/provider/ethereum.svg';
  const smsProviderAvatar = 'public/images/provider/sms.svg';
  const idmProviderAvatar = 'public/images/provider/idm.svg';
  const esiaProviderAvatar = 'public/images/provider/esia.png';
  const kloudProviderAvatar = 'public/images/provider/kloud.svg';

  const [providerTemplate, setProviderTemplate] = useState<TTemplate>({
    type: OauthProviderType.CUSTOM,
    name: '',
    pathToAvatar: '',
    scope: ProviderScope.internal,
  });

  const [isCreateFormTemplateOpen, setIsCreateFormTemplateOpen] = useState(false);
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);
  const [isCreateFormLdapOpen, setIsCreateFormLdapOpen] = useState(false);
  const [isCreateFormAldProOpen, setIsCreateFormAldProOpen] = useState(false);
  const [isCreateForm1COpen, setIsCreateForm1COpen] = useState(false);
  const [isCreateFormIdmOpen, setIsCreateFormIdmOpen] = useState(false);
  const [isCreateFormEsiaOpen, setIsCreateFormEsiaOpen] = useState(false);
  const [isCreateFormEthereumOpen, setIsCreateFormEthereumOpen] = useState(false);
  const [isCreateFormSmsOpen, setIsCreateFormSmsOpen] = useState(false);
  const [isCreateFormKloudOpen, setIsCreateFormKloudOpen] = useState(false);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [warningUniqueModalOpen, setWarningUniqueModalOpen] = useState(false);

  const providersAvatar: {
    type: OauthProviderType | MiscProviderType;
    pathToAvatar: string;
  }[] = [
    {
      type: OauthProviderType.YANDEX,
      pathToAvatar: 'public/images/provider/yandex.svg',
    },
    {
      type: OauthProviderType.VK,
      pathToAvatar: 'public/images/provider/vk.svg',
    },
    {
      type: OauthProviderType.MAILRU,
      pathToAvatar: 'public/images/provider/mail.svg',
    },
    {
      type: OauthProviderType.GOOGLE,
      pathToAvatar: 'public/images/provider/google.svg',
    },
    {
      type: OauthProviderType.CUSTOM,
      pathToAvatar: '',
    },
    //PRIVATE REPO
    {
      type: MiscProviderType.ESIA,
      pathToAvatar: 'public/images/provider/esia.png',
    },
    {
      type: MiscProviderType.LDAP,
      pathToAvatar: ldapProviderAvatar,
    },
    {
      type: MiscProviderType.ALDPRO,
      pathToAvatar: aldProProviderAvatar,
    },
    {
      type: MiscProviderType._1C,
      pathToAvatar: _1CProviderAvatar,
    },
    {
      type: MiscProviderType.IDM,
      pathToAvatar: idmProviderAvatar,
    },
    {
      type: MiscProviderType.QRCODE,
      pathToAvatar: 'public/images/provider/qrcode.svg',
    },
    //PRIVATE REPO END
    {
      type: MiscProviderType.KLOUD,
      pathToAvatar: kloudProviderAvatar,
    },
    {
      type: MiscProviderType.ETHEREUM,
      pathToAvatar: ethereumProviderAvatar,
    },
    {
      type: MiscProviderType.SMS,
      pathToAvatar: smsProviderAvatar,
    },
  ];

  const { data: rules = [] } = useGetRulesProvidersQuery();
  const { data: license } = useGetLicensesQuery();

  const filteredProviders: Array<TProviderRule & { pathToAvatar: string }> = rules.reduce(
    (acc, rule) => {
      if (rule.allowedScopes.includes(providerType) && rule.deletable) {
        const pathToAvatar = providersAvatar.find((provider) => provider.type === rule.type);
        acc.push({
          ...rule,
          pathToAvatar: pathToAvatar?.pathToAvatar || '',
        });
      }
      return acc;
    },
    [] as Array<TProviderRule & { pathToAvatar: string }>,
  );

  const handleClose = () => {
    close();
  };

  const handleProviderClose = (
    closeProvider: (arg: false) => void,
    closeChooseProvider?: boolean,
  ) => {
    closeProvider(false);
    if (closeChooseProvider) close();
  };

  return (
    <>
      <Drawer
        classes={{ paper: styles['drawer-paper'] }}
        BackdropProps={{ className: styles.backdrop }}
        onClose={handleClose}
        open={isOpen}
        anchor="right"
        variant="temporary"
      >
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <IconButton onClick={handleClose} className={styles['button-back']}>
              <ArrowTopIcon className={styles['arrow-icon']} />
            </IconButton>
            <CustomTypography className={clsx('text-24-medium', 'font-golos')}>
              {providerType === ProviderScope.trusted
                ? 'Создать провайдер'
                : 'Создать способ входа'}
            </CustomTypography>
            <IconButton
              sx={{
                '& path': theme.palette.custom.main,
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles['choose-provider']}>
            <CustomTypography className={clsx('font-golos', 'text-17-regular', styles.subtitle)}>
              {providerType === ProviderScope.trusted
                ? 'Выберите шаблон провайдера'
                : 'Выберите шаблон способа входа'}
            </CustomTypography>
            {filteredProviders.map((provider) => {
              return (
                <ListItem
                  key={provider.type}
                  className={styles.provider}
                  onClick={async () => {
                    if (
                      provider.license === 'license' &&
                      (!license || (license && !license.providers.includes(provider.type)))
                    ) {
                      return;
                    }

                    if (
                      provider.unique &&
                      providersList.find((item) => item.rule.type === provider.type)
                    ) {
                      if (provider.allowedScopes.includes(ProviderScope.trusted)) {
                        setWarningModalOpen(true);
                      } else {
                        setWarningUniqueModalOpen(true);
                      }
                      return;
                    }
                    try {
                      if (provider.type === OauthProviderType.CUSTOM) {
                        setIsCreateFormOpen(true);
                        //PRIVATE REPO
                      } else if (provider.type === MiscProviderType.LDAP) {
                        setIsCreateFormLdapOpen(true);
                      } else if (provider.type === MiscProviderType.ALDPRO) {
                        setIsCreateFormAldProOpen(true);
                      } else if (provider.type === MiscProviderType.IDM) {
                        setIsCreateFormIdmOpen(true);
                      } else if (provider.type === MiscProviderType.ESIA) {
                        setIsCreateFormEsiaOpen(true);
                      } else if (provider.type === MiscProviderType.QRCODE) {
                        await createProvider({
                          body: {
                            name: 'QR-код',
                            type: MiscProviderType.QRCODE,
                            path_to_avatar: provider.pathToAvatar,
                          },
                          client_id: clientId,
                        }).unwrap();
                        close();
                      } else if (provider.type === MiscProviderType._1C) {
                        setIsCreateForm1COpen(true);
                        //PRIVATE REPO END
                      } else if (provider.type === MiscProviderType.ETHEREUM) {
                        setIsCreateFormEthereumOpen(true);
                      } else if (provider.type === MiscProviderType.SMS) {
                        setIsCreateFormSmsOpen(true);
                      } else if (provider.type === MiscProviderType.KLOUD) {
                        setIsCreateFormKloudOpen(true);
                      } else if (
                        provider.type !== MiscProviderType.CREDENTIALS &&
                        provider.type !== MiscProviderType.EMAIL
                      ) {
                        setProviderTemplate({
                          ...providerTemplate,
                          type: provider.type,
                          name: provider.title,
                          pathToAvatar: provider.pathToAvatar,
                          scope: providerType,
                        });
                        setIsCreateFormTemplateOpen(true);
                      }
                    } catch (e) {
                      console.log('createProvider error', e);
                    }
                  }}
                >
                  <div
                    style={{
                      backgroundImage: `url(${BACKEND_URL + '/' + provider.pathToAvatar})`,
                    }}
                    className={styles['provider-icon-wrapper']}
                  >
                    {!provider.pathToAvatar && <IdIcon />}
                  </div>
                  <CustomTypography className={clsx('text-14', styles['provider-name'])}>
                    {provider.title || 'OpenID Connect'}
                  </CustomTypography>
                  {provider.license === 'license' &&
                    (!license || (license && !license.providers.includes(provider.type))) && (
                      <Tooltip
                        classes={{
                          tooltip: styles['input-tooltip'],
                        }}
                        title={<div>Операция недоступна. Требуется лицензия.</div>}
                      >
                        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                          <InfoIcon className={styles['info-icon']} color="red" />
                        </div>
                      </Tooltip>
                    )}
                </ListItem>
              );
            })}
          </div>
        </div>
      </Drawer>
      <CreateProviderByTemplate
        isOpen={isCreateFormTemplateOpen}
        close={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsCreateFormTemplateOpen, closeChooseProvider);
        }}
        providerTemplate={providerTemplate}
      />
      <CreateProvider
        isOpen={isCreateFormOpen}
        close={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsCreateFormOpen, closeChooseProvider);
        }}
        scope={providerType}
      />
      {
        //PRIVATE REPO
      }
      <CreateLdapProvider
        pathToAvatar={ldapProviderAvatar}
        isOpen={isCreateFormLdapOpen}
        close={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsCreateFormLdapOpen, closeChooseProvider);
        }}
        providerType={MiscProviderType.LDAP}
        scope={providerType}
      />
      <CreateLdapProvider
        pathToAvatar={aldProProviderAvatar}
        isOpen={isCreateFormAldProOpen}
        close={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsCreateFormAldProOpen, closeChooseProvider);
        }}
        providerType={MiscProviderType.ALDPRO}
        scope={providerType}
      />
      <Create1CProvider
        pathToAvatar={_1CProviderAvatar}
        isOpen={isCreateForm1COpen}
        close={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsCreateForm1COpen, closeChooseProvider);
        }}
        scope={providerType}
      />
      <CreateIdmProvider
        pathToAvatar={idmProviderAvatar}
        isOpen={isCreateFormIdmOpen}
        close={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsCreateFormIdmOpen, closeChooseProvider);
        }}
        scope={providerType}
      />
      <CreateEsiaProvider
        pathToAvatar={esiaProviderAvatar}
        isOpen={isCreateFormEsiaOpen}
        close={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsCreateFormEsiaOpen, closeChooseProvider);
        }}
        scope={providerType}
      />
      {
        //PRIVATE REPO END
      }
      <CreateEthereumProvider
        pathToAvatar={ethereumProviderAvatar}
        isOpen={isCreateFormEthereumOpen}
        close={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsCreateFormEthereumOpen, closeChooseProvider);
        }}
        scope={providerType}
      />
      <CreateSmsProvider
        pathToAvatar={smsProviderAvatar}
        isOpen={isCreateFormSmsOpen}
        close={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsCreateFormSmsOpen, closeChooseProvider);
        }}
        scope={providerType}
      />
      <CreateKloudProvider
        pathToAvatar={kloudProviderAvatar}
        isOpen={isCreateFormKloudOpen}
        close={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsCreateFormKloudOpen, closeChooseProvider);
        }}
        scope={providerType}
      />

      <ModalContinueOnly
        isOpen={warningUniqueModalOpen}
        onContinueAction={() => setWarningUniqueModalOpen(false)}
        title="Создание уникального провайдера"
        message="Для добавления нового провайдера данного типа перейдите в список провайдеров и удалите
            ранее созданный."
      />

      <ModalContinueOnly
        isOpen={warningModalOpen}
        onContinueAction={() => setWarningModalOpen(false)}
        title="Создание доверенного провайдера"
        message="Для добавления нового доверенного провайдера прейдите в список провайдеров и удалите
            ранее созданный."
      />
    </>
  );
};
