import React from 'react';
import { ThemeColors } from './Theme';
import { useTheme } from '@mui/material/styles';

type TFieldsToCusom = 'fill' | 'ellipse' | 'stroke';

export const CustomIcon = ({
  Icon,
  color,
  fieldsToCustom = ['fill'],
  ...props
}: React.SVGProps<SVGSVGElement> & {
  title?: string;
} & {
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
  color?: ThemeColors;
  fieldsToCustom?: TFieldsToCusom[];
}) => {
  const theme = useTheme();

  const getColor = () => {
    if (color === 'grey') return theme.palette.custom.mainGrey;
    if (color === 'error') return theme.palette.custom.error;
    if (color === 'link') return theme.palette.custom.link;
    return theme.palette.custom.main;
  };

  const mapFieldsToCustom = fieldsToCustom.reduce((prev, cur) => {
    if (cur === 'fill') return { ...prev, fill: getColor() };
    if (cur === 'ellipse') return { ...prev, ellipse: getColor() };
    if (cur === 'stroke') return { ...prev, stroke: getColor() };
    return prev;
  }, {});

  return <Icon {...props} stroke={getColor()} />;
};
