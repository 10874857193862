import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import React, { FC, MouseEvent, ReactElement, useCallback, useState } from 'react';
import { ReactComponent as WorldIcon } from '../../icons/World.svg';
import { ReactComponent as LockIcon } from '../../icons/Lock.svg';
import { ReactComponent as ArrowDownIcon } from '../../icons/ArrowDown.svg';
import { ReactComponent as GroupIcon } from '../../icons/Group.svg';
import styles from './PublicStatusPopover.module.css';
import { useChangeClaimPrivacyMutation } from '../../redux/services/user';
import { PrivateScopesMode } from '../../requests/user';
import { CustomTypography } from '../custom/CustomTypography';
import { CustomIcon } from '../custom/CustomIcon';

export enum EClaimPrivacy {
  publicOauth = 'publicOauth',
  publicGravatar = 'publicGravatar',
  private = 'private',
}

type PublicStatusPopoverProps = {
  claimPrivacy: EClaimPrivacy;
  claims?: string;
  userId?: string;
  disabled?: boolean;
  externalAccountId?: number;
  setStatus?: (status: EClaimPrivacy) => void;
};

export const PublicStatusPopover: FC<PublicStatusPopoverProps> = ({
  claimPrivacy,
  claims,
  disabled,
  externalAccountId,
  userId,
  setStatus,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [changeClaimPrivacy] = useChangeClaimPrivacyMutation();

  const openPopover = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closePopover = (event: MouseEvent<HTMLButtonElement | ReactElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleButtonClick = async (
    event: MouseEvent<HTMLButtonElement | ReactElement>,
    privacyMode: PrivateScopesMode,
  ) => {
    closePopover(event);
    if (userId) {
      if (claims) return changeClaimPrivacy({ claims, privacyMode, userId });
      if (externalAccountId)
        return changeClaimPrivacy({
          userId,
          privacyMode,
          public_accounts_to_edit: [externalAccountId],
        });
    }
    if (setStatus) {
      let newStatus: EClaimPrivacy = EClaimPrivacy.private;
      if (privacyMode === PrivateScopesMode.SET_PUBLIC_OAUTH) newStatus = EClaimPrivacy.publicOauth;
      if (privacyMode === PrivateScopesMode.SET_PUBLIC_GRAVATAR)
        newStatus = EClaimPrivacy.publicGravatar;
      setStatus(newStatus);
    }
  };

  const GetPublicityTooltip = useCallback(() => {
    if (claimPrivacy === EClaimPrivacy.private)
      return (
        <Tooltip arrow title="Доступно только вам">
          <IconButton
            onClick={(e) => {
              openPopover(e);
            }}
            className={styles.button}
          >
            <LockIcon />
            <CustomIcon Icon={ArrowDownIcon} width={16} className={styles['arrow-down']} />
          </IconButton>
        </Tooltip>
      );

    if (claimPrivacy === EClaimPrivacy.publicOauth)
      return (
        <Tooltip arrow title="Доступно по запросу">
          <IconButton
            onClick={(e) => {
              openPopover(e);
            }}
            className={styles.button}
          >
            <GroupIcon className={styles['public-icon']} />
            <CustomIcon Icon={ArrowDownIcon} width={16} className={styles['arrow-down']} />
          </IconButton>
        </Tooltip>
      );
    return (
      <Tooltip arrow title="Доступно всем">
        <IconButton
          onClick={(e) => {
            openPopover(e);
          }}
          className={styles.button}
        >
          <WorldIcon className={styles['public-icon']} />
          <CustomIcon Icon={ArrowDownIcon} width={16} className={styles['arrow-down']} />
        </IconButton>
      </Tooltip>
    );
  }, [claimPrivacy]);

  return (
    <div className={clsx(disabled ? styles['lock-button-disabled'] : styles['lock-button'])}>
      <GetPublicityTooltip />
      {!disabled && (
        <Popover
          classes={{
            paper: clsx(styles.paper),
          }}
          onClose={closePopover}
          anchorEl={anchorEl}
          open={!!anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Button
            startIcon={<LockIcon />}
            className={clsx(styles['popover-button'], {
              [styles['selected-popover-button']]: claimPrivacy === EClaimPrivacy.private,
            })}
            onClick={(e) => {
              claimPrivacy !== EClaimPrivacy.private
                ? handleButtonClick(e, PrivateScopesMode.SET_PRIVATE)
                : closePopover(e);
            }}
          >
            <div className={styles['popover-info']}>
              <CustomTypography className={clsx('text-14')}>Доступно только вам</CustomTypography>
              <CustomTypography className={clsx('text-12')} color="grey">
                Данные конфиденциальны и видны только вам
              </CustomTypography>
            </div>
          </Button>
          <Button
            startIcon={<GroupIcon />}
            className={clsx(styles['popover-button'], {
              [styles['selected-popover-button']]: claimPrivacy === EClaimPrivacy.publicOauth,
            })}
            onClick={(e) => {
              claimPrivacy !== EClaimPrivacy.publicOauth
                ? handleButtonClick(e, PrivateScopesMode.SET_PUBLIC_OAUTH)
                : closePopover(e);
            }}
          >
            <div className={styles['popover-info']}>
              <CustomTypography className={clsx('text-14')}>Доступно по запросу</CustomTypography>
              <CustomTypography className={clsx('text-12')} color="grey">
                Доступ к данным будет предоставлен после вашего согласия
              </CustomTypography>
            </div>
          </Button>
          <Button
            startIcon={<WorldIcon />}
            className={clsx(styles['popover-button'], {
              [styles['selected-popover-button']]: claimPrivacy === EClaimPrivacy.publicGravatar,
            })}
            onClick={(e) => {
              claimPrivacy !== EClaimPrivacy.publicGravatar
                ? handleButtonClick(e, PrivateScopesMode.SET_PUBLIC_GRAVATAR)
                : closePopover(e);
            }}
          >
            <div className={styles['popover-info']}>
              <CustomTypography className={clsx('text-14')}>Доступно всем</CustomTypography>
              <CustomTypography className={clsx('text-12')} color="grey">
                Данные публичны и доступны всем
              </CustomTypography>
            </div>
          </Button>
        </Popover>
      )}
    </div>
  );
};
