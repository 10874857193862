import React, { ChangeEvent, FC } from 'react';
import styles from './EditProvider.module.css';
import clsx from 'clsx';
import TextField from '@mui/material/TextField';
import { ReactComponent as IdIcon } from '../icons/Id.svg';
import { useFormContext } from 'react-hook-form';
import { UploadAndDisplayImage } from './UploadAndDisplayImage';
import { TMiscProvider, TOauthProvider } from '../redux/services/provider';
import { ProviderScope } from '../redux/services/settings';
import { CustomTypography } from './custom/CustomTypography';

type TProviderHeader = {
  type: ProviderScope;
  watchDescription: string;
  overrideImage: File | string | null;
  setAvatarError: (error: string) => void;
  clearAvatarError: () => void;
  providerToEdit?: Partial<(TOauthProvider | TMiscProvider) & { provider_id: string }> | null;
  setAvatarValue: (value: File | null) => void;
  pathToAvatar?: string;
  setAvatarLink?: (value: string) => void;
  imgSrc: string | null;
  setImgSrc: (value: string | null) => void;
};

export const ProviderHeader: FC<TProviderHeader> = ({
  type,
  watchDescription,
  overrideImage,
  setAvatarError,
  clearAvatarError,
  providerToEdit,
  setAvatarValue,
  pathToAvatar,
  setAvatarLink,
  imgSrc,
  setImgSrc,
}) => {
  const {
    register,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <CustomTypography className={clsx('font-golos', 'text-17-regular', styles.subtitle)}>
        Основная информация
      </CustomTypography>
      <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
        {type === ProviderScope.login ? 'Название способа входа' : 'Название провайдера'}
      </CustomTypography>
      <TextField
        {...register('name', {
          required: true,
          onChange: () => {
            if (errors.name) clearErrors('name');
          },
        })}
        className="custom"
        error={!!errors.name}
        helperText={errors.name ? errors.name.message : ''}
        fullWidth
        variant="standard"
      />
      <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
        {type === ProviderScope.login
          ? 'Имя способа входа, отображаемое пользователям'
          : 'Имя провайдера, отображаемое пользователям'}
      </CustomTypography>
      <CustomTypography className={clsx('text-14', styles['input-title'])}>
        {type === ProviderScope.login ? 'Описание способа входа' : 'Описание провайдера'}
      </CustomTypography>
      <TextField
        {...register('description', {
          onChange: (event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.value.length > 255) {
              setError('description', {
                message: 'Невозможно ввести более 255 символов',
              });
              setValue('description', event.target.value.slice(0, 255));
            } else if (errors.description) clearErrors('description');
          },
        })}
        className="custom"
        error={!!errors.description}
        helperText={errors.description ? errors.description.message : ''}
        fullWidth
        variant="standard"
        multiline
      />
      <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
        {watchDescription?.length}/255 символов
      </CustomTypography>
      <div>
        <CustomTypography className={clsx('text-14', styles['input-title'])}>
          {type === ProviderScope.login ? 'Логотип способа входа' : 'Логотип провайдера'}
        </CustomTypography>
        <UploadAndDisplayImage
          overRideImageSrc={overrideImage}
          setAvatarError={setAvatarError}
          clearAvatarError={clearAvatarError}
          defaultValue={providerToEdit?.avatar || null}
          setAvatarValue={setAvatarValue}
          componentName="create-provider"
          DefaultIcon={<IdIcon fill="#CED0D9" className={styles['id-icon']} />}
          pathToAvatar={pathToAvatar}
          setAvatarLink={setAvatarLink}
          imgSrc={imgSrc}
          setImgSrc={setImgSrc}
        />
        {errors.avatar && (
          <CustomTypography color="error" className={clsx('text-14', styles['input-error'])}>
            {errors.avatar.message}
          </CustomTypography>
        )}
        <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
          Файл с расширением .jpg, .jpeg, .png, .svg. Максимальный размер - 1 МБ.
        </CustomTypography>
      </div>
      <div className={styles.divider} />
      <CustomTypography className={clsx('text-17', styles.subtitle)}>
        {type === ProviderScope.login ? 'Параметры способа входа' : 'Параметры провайдера'}
      </CustomTypography>
    </>
  );
};
