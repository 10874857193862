import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Roles } from '../enums';

export type TCustomFields = { [key: string]: string };

export type TUserProfile = {
  birthdate?: string;
  email?: string;
  nickname?: string;
  phone_number?: string | null;
  login?: string;
  id?: string;
  role?: Roles;
  given_name?: string;
  family_name?: string;
  password_updated_at?: string;
  picture?: string | null;
  password_change_required?: boolean;
  public_accounts_claims_oauth?: number[];
  public_accounts_claims_gravatar?: number[];
  deleted?: string;
  custom_fields?: TCustomFields;
};

export type TFilter = {
  level?: string;
  start_date?: string;
  end_date?: string;
  client_id?: string;
  user_id?: string;
  ip_address?: string;
  event?: string;
  description?: string;
};

export type TUserSlice = {
  isAuthorized: boolean;
  userProfile: TUserProfile;
  filter: TFilter;
  filterCount: number;
  isUserProfileLoading: boolean;
  userRoleInApp?: Roles;
};

const initialState: TUserSlice = {
  isAuthorized: false,
  userProfile: {},
  filter: {},
  filterCount: 0,
  userRoleInApp: undefined,
  isUserProfileLoading: true,
};

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setIsAuthorized(state, action: PayloadAction<TUserSlice['isAuthorized']>) {
      state.isAuthorized = action.payload;
    },

    setUserProfile(state, action: PayloadAction<TUserSlice['userProfile']>) {
      state.userProfile = { ...state.userProfile, ...action.payload };
    },

    setIsUserProfileLoading(state, action: PayloadAction<boolean>) {
      state.isUserProfileLoading = action.payload;
    },

    setFilter(state, action: PayloadAction<TUserSlice['filter']>) {
      state.filter = { ...state.filter, ...action.payload };
    },
    setFilterCount(state, action: PayloadAction<TUserSlice['filterCount']>) {
      state.filterCount = action.payload;
    },
    setUserRoleInApp(state, action: PayloadAction<TUserSlice['userRoleInApp']>) {
      state.userRoleInApp = action.payload;
    },
  },
});

export const {
  setIsAuthorized,
  setUserProfile,
  setFilter,
  setFilterCount,
  setUserRoleInApp,
  setIsUserProfileLoading,
} = userSlice.actions;
export default userSlice.reducer;
