import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL } from '../../constants';
import { getAccessToken } from '../../service/auth';

export const ldapApi = createApi({
  reducerPath: 'ldapApi',

  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}/api/ldap/v1`,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),

  endpoints: (builder) => ({
    checkLdapPasswordChangeRequire: builder.query<
      boolean,
      {
        username: string;
        password: string;
        provider_id: string;
      }
    >({
      query: (body) => {
        return {
          url: `/check_password_change_required`,
          method: 'POST',
          body,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        };
      },
    }),

    changeLdapPassword: builder.mutation<
      undefined,
      {
        username: string;
        current_password: string;
        new_password: string;
        provider_id: string;
      }
    >({
      query: (body) => ({
        url: `/change_pass`,
        method: 'PUT',
        body,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }),
    }),
  }),
});

export const { useLazyCheckLdapPasswordChangeRequireQuery, useChangeLdapPasswordMutation } =
  ldapApi;
