import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import React, { FC } from 'react';
import styles from './ProviderColor.module.css';
import ColorPicker from './ColorPicker';
import { EditLdapProviderInputs } from './EditLdapProvider';
import { useFormContext, useWatch } from 'react-hook-form';
import { replaceJSX } from '../helpers';
import { BACKEND_URL } from '../constants';
import { CustomTypography } from './custom/CustomTypography';

export type TProviderColors = { button_color: string; font_color: string };

type TLdapColor = {
  avatarSrc: string | null;
  providerName?: string;
};

export const ProviderColor: FC<TLdapColor> = ({ providerName, avatarSrc }) => {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext<EditLdapProviderInputs>();
  const buttonColor = useWatch({ control, name: 'provider_colors.button_color' });
  const fontColor = useWatch({ control, name: 'provider_colors.font_color' });
  const ldapTitle = useWatch({ control, name: 'provider_title' });
  const showAvatar = useWatch({ control, name: 'show_provider_avatar' });

  return (
    <div className={styles['ldap-color']}>
      <CustomTypography className={clsx('text-14', styles['color-title'])}>
        Заголовок способа входа
      </CustomTypography>
      <TextField
        {...register('provider_title')}
        className="custom"
        fullWidth
        variant="standard"
        style={{ marginBottom: 32 }}
      />
      <div className={styles['flex-container']}>
        <div className={styles['set-colors-container']}>
          <div className={styles['set-color']}>
            <CustomTypography className={clsx('text-14', styles['color-title'])}>
              Цвет кнопок
            </CustomTypography>
            <ColorPicker componentName="provider_colors" colorName="button_color" />
            {errors.provider_colors?.button_color && (
              <CustomTypography color="error" className={clsx('text-14', styles['color-error'])}>
                {errors.provider_colors?.button_color.message}
              </CustomTypography>
            )}
          </div>
          <div className={styles['set-color']}>
            <CustomTypography className={clsx('text-14', styles['color-title'])}>
              Цвет шрифта на кнопках
            </CustomTypography>
            <ColorPicker componentName="provider_colors" colorName="font_color" />
            {errors.provider_colors?.font_color && (
              <CustomTypography color="error" className={clsx('text-14', styles['color-error'])}>
                {errors.provider_colors?.font_color.message}
              </CustomTypography>
            )}
          </div>
        </div>
        <div className={styles['ldap-wrapper']}>
          <CustomTypography className={clsx('text-14', styles.preview)}>Превью:</CustomTypography>
          <div className={styles.ldap}>
            <CustomTypography className={styles['ldap-title']}>
              Авторизуйтесь пользователем Trusted
            </CustomTypography>
            <div className={styles.divider} />
            <div className={styles['title-container']}>
              {showAvatar && avatarSrc && (
                <img
                  src={`${(avatarSrc?.startsWith('data:') ? '' : BACKEND_URL + '/') + avatarSrc}`}
                  width="40px"
                  height="40px"
                  style={{ margin: '0 10px' }}
                />
              )}
              <CustomTypography className={clsx(styles['ldap-subtitle'])}>
                {replaceJSX(
                  ldapTitle || '',
                  'PROVIDER_TITLE',
                  <span style={{ color: buttonColor }}>{providerName}</span>,
                )}
              </CustomTypography>
            </div>
            <TextField
              label="Логин"
              variant="standard"
              InputProps={{
                classes: {
                  underline: styles['input-underline'],
                  focused: styles['ldap-input-label-focused'],
                },
              }}
              InputLabelProps={{
                className: styles['ldap-input-label'],
                classes: {
                  focused: styles['ldap-input-label-focused'],
                  asterisk: styles['disable-asterisk'],
                },
              }}
              fullWidth
              className={styles['text-field']}
            />
            <TextField
              label="Пароль"
              variant="standard"
              InputProps={{
                classes: {
                  underline: styles['input-underline'],
                  focused: styles['ldap-input-label-focused'],
                },
              }}
              InputLabelProps={{
                className: styles['ldap-input-label'],
                classes: {
                  focused: styles['ldap-input-label-focused'],
                  asterisk: styles['disable-asterisk'],
                },
              }}
              fullWidth
              className={styles['text-field']}
              type="password"
            />
            <div className={styles['ldap-buttons']}>
              <Button style={{ color: buttonColor }} variant="custom2">
                СМЕНИТЬ ПАРОЛЬ
              </Button>
              <Button style={{ background: buttonColor, color: fontColor }} variant="custom">
                ВОЙТИ
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
