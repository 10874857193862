import React from 'react';
import { PatternFormat, PatternFormatProps } from 'react-number-format';

export const NumericFormatCustom = React.forwardRef<
  PatternFormatProps,
  {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
  }
>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onPaste={(e: React.ClipboardEvent) => {
        const newValue = e.clipboardData.getData('text/plain')?.replace('+7', '');
        if (newValue.length === 10) {
          onChange({
            target: {
              name: props.name,
              value: newValue,
            },
          });
          e.preventDefault();
        }
      }}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="+7 (###) ###-####"
      mask="_"
      allowEmptyFormatting
    />
  );
});
