import { BACKEND_URL } from '../constants';
import { getAccessToken } from '../service/auth';

export type Client = {
  name: string;
  client_id: string;
  client_secret: string;
  redirect_uris: string[];
  post_logout_redirect_uris: string[];
  registration_access_token: string;
};

export const changePermissionInApp = async (clientId: string, userId: number, role: string) => {
  try {
    const accessToken = await getAccessToken();
    await fetch(`${BACKEND_URL}/api/user/v1/role/${clientId}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: `${encodeURIComponent('user_id')}=${encodeURIComponent(userId)}&${encodeURIComponent(
        'role',
      )}=${encodeURIComponent(role)}`,
    });
  } catch (e) {
    console.log('changePermissionInApp error: ', e);
  }
};
