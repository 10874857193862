import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL } from '../../constants';
import { getAccessToken } from '../../service/auth';

export enum SmsCodeTypes {
  authorization = 'authorization',
  addPhone = 'addPhone',
}

export const smsApi = createApi({
  reducerPath: 'smsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}/api/sms/v1`,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    checkPhoneNumberAvialability: builder.query<
      { isAvailable: boolean; nickname?: string },
      string
    >({
      query: (phoneNumber) => `/check?phoneNumber=${encodeURIComponent(phoneNumber)}`,
    }),

    sendSmsCode: builder.mutation<
      { message?: string },
      {
        uid?: string;
        phoneNumber: string;
        providerId: string;
        type: SmsCodeTypes;
        userId?: string;
        name?: string;
        resend?: boolean;
        rebind?: boolean;
      }
    >({
      query: ({ uid, phoneNumber, type, userId, name, resend, rebind, providerId }) => {
        let body =
          'code_type=' +
          type +
          '&phoneNumber=' +
          encodeURIComponent(phoneNumber) +
          '&provider_id=' +
          providerId +
          '&uid=' +
          uid;
        if (userId) body += '&user_id=' + userId;
        if (name) body += '&name=' + name;
        if (resend) body += '&resend=' + resend;
        if (rebind) body += '&rebind=' + rebind;

        return {
          url: 'confirmation_code',
          body,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded ',
          },
          method: 'POST',
        };
      },
    }),

    getSMSCredentials: builder.query<
      { admin_login: string; admin_password: string },
      { client_id: string; provider_id: string }
    >({
      query: ({ client_id, provider_id }) => ({
        url: `/credentials/${client_id}/${provider_id}`,
      }),
    }),
  }),
});

export const {
  useLazyCheckPhoneNumberAvialabilityQuery,
  useSendSmsCodeMutation,
  useLazyGetSMSCredentialsQuery,
} = smsApi;
