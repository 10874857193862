import { useEffect, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useEffectOnce(fn: () => boolean | undefined, deps?: any) {
  const isApplied = useRef(false);

  useEffect(() => {
    let res;
    if (!isApplied.current) res = fn();
    if (res !== false) isApplied.current = true;
  }, deps);
}
