import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL } from '../../constants';
import { getAccessToken } from '../../service/auth';

export const phoneApi = createApi({
  reducerPath: 'phoneApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}/api/phone/v1`,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    checkPhoneExists: builder.query<{ isAvailable: boolean }, string>({
      query: (phoneNumber) => `/check?phone_number=${encodeURIComponent(phoneNumber)}`,
    }),

    callPhone: builder.mutation<
      { success: boolean; code_type: string; code_length?: number; message?: string },
      string
    >({
      query: (phoneNumber) => ({
        url: `/confirmation_code?phone_number=${encodeURIComponent('+7' + phoneNumber)}`,
        method: 'POST',
      }),
    }),
  }),
});

export const { useCallPhoneMutation, useLazyCheckPhoneExistsQuery } = phoneApi;
