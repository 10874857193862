import React, { Dispatch, FC, SetStateAction } from 'react';
import styles from './UserPopover.module.css';
import { Link, useLocation } from 'react-router-dom';
import { useLazyDeleteAllSessionQuery } from '../../redux/services/auth';
import { PaperProps } from '@mui/material/Paper';
import Popover, { PopoverClasses, PopoverOrigin } from '@mui/material/Popover';
import { CustomPopoverButton } from '../custom/CustomPopoverButton';
import { ReactComponent as AvatarIcon } from '../../icons/Avatar.svg';
import { ReactComponent as OwnerIcon } from '../../icons/Owner.svg';
import { ReactComponent as QuitIcon } from '../../icons/Quit.svg';
import { CLIENT_ID } from '../../constants';

type UserPopoverProps = {
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  onClose: () => void;
  anchorOrigin: PopoverOrigin;
  transformOrigin: PopoverOrigin;
  classes: Partial<PopoverClasses>;
  PaperProps?: Partial<PaperProps>;
  userId: number;
  clientId?: string;
  loggedUserId?: string;
  setChangePermissionsModalOpen: Dispatch<SetStateAction<boolean>>;
};

export const UserPopover: FC<UserPopoverProps> = ({
  open,
  anchorEl,
  setAnchorEl,
  onClose,
  anchorOrigin,
  transformOrigin,
  classes,
  PaperProps,
  userId,
  clientId,
  loggedUserId,
  setChangePermissionsModalOpen,
}) => {
  const [deleteAllSession] = useLazyDeleteAllSessionQuery();
  const path = useLocation().pathname;
  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      classes={classes}
      PaperProps={PaperProps}
    >
      {!path.startsWith('/applications/user/') && (
        <Link
          style={{ display: 'block', width: '100%', textDecoration: 'none' }}
          to={{
            pathname:
              Number(loggedUserId) === userId
                ? '/profile'
                : `/applications/user/${clientId}/${userId}`,
            search: `?prevPath=${location.pathname}`,
          }}
        >
          <CustomPopoverButton startIcon={<AvatarIcon className={styles['action-button-icon']} />}>
            Посмотреть профиль
          </CustomPopoverButton>
        </Link>
      )}
      {clientId !== CLIENT_ID && (
        <CustomPopoverButton
          onClick={(e) => {
            e.stopPropagation();
            setChangePermissionsModalOpen(true);
            setAnchorEl(null);
          }}
          startIcon={<OwnerIcon className={styles['action-button-icon']} />}
        >
          Изменить полномочия
        </CustomPopoverButton>
      )}
      <CustomPopoverButton
        startIcon={<QuitIcon className={styles['action-button-icon']} />}
        onClick={async () => {
          await deleteAllSession(userId);
          window.location.reload();
        }}
      >
        Завершить все сеансы
      </CustomPopoverButton>
    </Popover>
  );
};
