import React, { FC, MouseEvent, ReactElement, useState } from 'react';
import { Roles } from '../../enums';
import {
  TUser,
  TUserCount,
  useChangeUsersRoleMutation,
  useLazyGetUserCountQuery,
} from '../../redux/services/client';
import { CustomCheckbox } from '../custom/CustomCheckbox';
import { ReactComponent as BasketIcon } from '../../icons/Basket.svg';
import { ReactComponent as AppIcon } from '../../icons/App.svg';
import { ReactComponent as LockIcon } from '../../icons/Lock.svg';
import { ReactComponent as OwnerIcon } from '../../icons/Owner.svg';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { ReactComponent as QuitIcon } from '../../icons/Quit.svg';
import { ReactComponent as ActionsIcon } from '../../icons/Actions.svg';
import { ReactComponent as RefreshIcon } from '../../icons/Refresh.svg';
import {
  useBlockUsersMutation,
  useDeleteUsersInfoMutation,
  useDeleteUsersMutation,
  useDeleteUsersSessionsMutation,
  useUnblockUsersMutation,
} from '../../redux/services/owner';
import { TSettings } from '../../redux/services/client';
import styles from './UsersControlMultipleOperations.module.css';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import clsx from 'clsx';
import { BACKEND_URL, PROJECT_NAME } from '../../constants';
import { checkForNumberEnding, getImageURL, isAdministrator, isOwner } from '../../helpers';
import { CustomRadioButton } from '../CustomRadioButton';
import { useDispatch } from 'react-redux';
import { setNotice } from '../../redux/noticesSlice';
import { getDeclinationByNumber } from '../../helpers';
import { CustomButton } from '../custom/CustomButton';
import { CustomPopoverButton } from '../custom/CustomPopoverButton';
import { CLIENT_ID } from '../../constants';
import { Link } from 'react-router-dom-v5-compat';
import { CustomTypography } from '../custom/CustomTypography';

type TUsersControlMultipleOperationsProps = {
  width: number;
  setAllUsersChecked: React.Dispatch<React.SetStateAction<boolean>>;
  getActiveUsersCountLoading: boolean;
  checkedActiveUsersCount: number;
  allUsersChecked: boolean;
  checkedUsers: { user: TUser; role: string }[];
  uncheckedUsers: { user: TUser; role: string }[];
  searchValue: string;
  setCheckedUsers: React.Dispatch<
    React.SetStateAction<
      {
        user: TUser;
        role: string;
      }[]
    >
  >;
  clearUsers: (refreshUserCount?: boolean) => void;
  onSearch: (searchString: string) => Promise<void>;
  users: ({ user: Partial<TUser>; role: string } | undefined)[];
  handleCheckAllUsers: () => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  refetchUsersCount: Function;
  allCheckedUsersLength: number;
  ownerId: number;
  dataSettings?: TSettings;
  search_filter?: string[];
};

export const UsersControlMultipleOperations: FC<TUsersControlMultipleOperationsProps> = ({
  width,
  checkedUsers,
  allUsersChecked,
  searchValue,
  checkedActiveUsersCount,
  clearUsers,
  users,
  getActiveUsersCountLoading,
  uncheckedUsers,
  handleCheckAllUsers,
  refetchUsersCount,
  allCheckedUsersLength,
  ownerId,
  dataSettings,
  search_filter,
}) => {
  const [operationsPopoverAnchorEl, setOperationsPopoverAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [deleteUsersModalOpen, setDeleteUsersModalOpen] = useState(false);
  const [deleteUsersSessionsModalOpen, setDeleteUsersSessionsModalOpen] = useState(false);
  const [changePermissionsModalOpen, setChangePermissionsModalOpen] = useState(false);
  const [blockUsersModalOpen, setBlockUsersModalOpen] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState<
    Roles.ADMIN | Roles.EDITOR | Roles.USER | Roles.OWNER | undefined
  >(undefined);
  const [deleteAppsWithUser, setDeleteAppsWithUser] = useState(false);
  const [usersCountState, setUsersCountState] = useState<TUserCount | null>(null);

  const dispatch = useDispatch();
  const haveActiveUsers =
    ((allUsersChecked || !!uncheckedUsers.length) && checkedActiveUsersCount > 0) ||
    checkedUsers.some((selectedUser) => !selectedUser.user.blocked);
  const checkedUsersCount =
    allUsersChecked || !!uncheckedUsers.length
      ? allCheckedUsersLength - uncheckedUsers.length
      : checkedUsers.length;
  const usersToBlockCount = haveActiveUsers
    ? checkedActiveUsersCount
    : checkedUsersCount - checkedActiveUsersCount;
  const getUsersToChangeRoleCount = () => {
    if (usersCountState) {
      if (checkedUsers.length)
        return checkedUsers.reduce((acc, { role: checkedUserRole }) => {
          return acc + (checkedUserRole !== selectedPermission ? 1 : 0);
        }, 0);
      switch (selectedPermission) {
        case Roles.USER:
          return usersCountState.adminCount + usersCountState.ownerCount;
        case Roles.ADMIN:
          return usersCountState.userCount - usersCountState.adminCount;
        case Roles.OWNER:
          return usersCountState.userCount - usersCountState.ownerCount;
      }
    }
  };

  const [changeUsersRole, { isLoading: changeUsersRoleLoading }] = useChangeUsersRoleMutation();
  const [blockUsers, { isLoading: blockUsersLoading }] = useBlockUsersMutation();
  const [unblockUsers, { isLoading: unblockUsersLoading }] = useUnblockUsersMutation();
  const [getUserCount, { isFetching: getUsersCountFetching }] = useLazyGetUserCountQuery();
  const [getDeleteUsersInfo, { data: deleteUsersInfoData, isLoading: deleteUsersInfoLoading }] =
    useDeleteUsersInfoMutation();
  const [deleteUsers, { isLoading: deleteUsersLoading }] = useDeleteUsersMutation();
  const [deleteUsersSessions, { isLoading: deleteUsersSessionsLoading }] =
    useDeleteUsersSessionsMutation();
  const disableButtons =
    blockUsersLoading || unblockUsersLoading || deleteUsersLoading || changeUsersRoleLoading;
  const applicationsToDelete =
    deleteUsersInfoData?.apps.filter((app) => app.onlyEditor).map((app) => app.client) || [];
  const usersToDelete = deleteUsersInfoData?.users_ids || [];

  const getOnlyCheckedUser = () => {
    if (users.length === 1 && allUsersChecked) return users[0];
    if (checkedUsersCount === 1 && checkedUsers.length) return checkedUsers[0];

    if (uncheckedUsers.length && checkedUsersCount === 1)
      return users.find((user) =>
        uncheckedUsers.every((uncheckedUser) => uncheckedUser.user.id !== user?.user.id),
      );

    return null;
  };

  const getOperationsCount = () => {
    if (width > 1000) return 4;
    if (width > 900) return 3;
    if (width > 700) return 2;
    if (width > 400) return 1;
    return 0;
  };
  const operationsCount = getOperationsCount();

  const closeChangePermissionModal = () => {
    if (!disableButtons) {
      setSelectedPermission(undefined);
      setChangePermissionsModalOpen(false);
    }
  };

  const getOnlyUserToChangeRole = () => {
    const onlyCheckedUser = getOnlyCheckedUser();

    const usersToChangeRoleCount = getUsersToChangeRoleCount();
    if ((usersToChangeRoleCount && usersToChangeRoleCount > 1) || !selectedPermission) return null;
    if (onlyCheckedUser) return onlyCheckedUser;
    if (allUsersChecked) return users.find((user) => user?.role !== selectedPermission);
    if (uncheckedUsers.length)
      return users.find(
        (user) =>
          user?.role !== selectedPermission &&
          !uncheckedUsers.find((uncheckedUser) => uncheckedUser.user.id === user?.user.id),
      );
    return checkedUsers.find(({ role }) => role !== selectedPermission);
  };

  const handleDeleteUsersSessions = async () => {
    try {
      const onlyCheckedUser = getOnlyCheckedUser();

      if (allUsersChecked || !!uncheckedUsers.length) {
        await deleteUsersSessions({
          all: true,
          unchecked_ids: uncheckedUsers.map(({ user }) => user.id),
          search_string: searchValue,
        }).unwrap();
      } else {
        await deleteUsersSessions({
          checked_ids: checkedUsers.map(({ user }) => user.id),
        }).unwrap();
      }

      setDeleteUsersSessionsModalOpen(false);

      dispatch(
        setNotice({
          id: Math.random(),
          isRead: false,
          message:
            checkedUsersCount > 1
              ? `Сеансы пользователей завершены.`
              : `Сеансы ${onlyCheckedUser?.user.nickname || 'пользователя'} завершены.`,
          timestamp: new Date().toString(),
          avatar: checkedUsersCount > 1 ? null : onlyCheckedUser?.user.picture,
        }),
      );
    } catch (e) {
      console.log('handleDeleteUsersSessions error:', e);
    }
  };

  const handleSaveRole = async () => {
    try {
      const role = selectedPermission;
      if (!role) return;
      const usersToChangeRoleCount = getUsersToChangeRoleCount();

      if (allUsersChecked || !!uncheckedUsers.length) {
        await changeUsersRole({
          all: true,
          role,
          search_string: searchValue,
          unchecked_ids: [1, ownerId, ...uncheckedUsers.map(({ user }) => user.id)],
          client_id: CLIENT_ID,
          search_filter,
        }).unwrap();
      } else if (checkedUsers.length) {
        if (selectedPermission === Roles.ADMIN) {
          const usersIds = checkedUsers
            .filter(({ role }) => role !== Roles.ADMIN)
            .map(({ user }) => user.id);

          await changeUsersRole({
            checked_ids: usersIds,
            role: selectedPermission,
            client_id: CLIENT_ID,
          }).unwrap();
        } else {
          await changeUsersRole({
            checked_ids: checkedUsers.map(({ user }) => user.id),
            role,
            client_id: CLIENT_ID,
          }).unwrap();
        }
      }

      if (usersToChangeRoleCount) {
        const userTitleEnding = checkForNumberEnding(usersToChangeRoleCount)
          ? 'пользователя'
          : 'пользователей';

        dispatch(
          setNotice({
            id: Math.random(),
            isRead: false,
            message:
              usersToChangeRoleCount > 1
                ? `Права ${
                    usersToChangeRoleCount + ' ' + userTitleEnding
                  } изменены в ${PROJECT_NAME}.`
                : `Права ${
                    getOnlyUserToChangeRole()?.user.nickname || 'пользователя'
                  } в ${PROJECT_NAME} изменены.`,
            timestamp: new Date().toString(),
            avatar: usersToChangeRoleCount > 1 ? null : getOnlyUserToChangeRole()?.user.picture,
          }),
        );
      }

      clearUsers();
    } catch (e) {
      console.log('handleSaveRole error:', e);
    }
  };

  const handleBlockUsers = async () => {
    try {
      const onlyCheckedUser = getOnlyCheckedUser();

      const isBlock =
        checkedActiveUsersCount > 0 ||
        checkedUsers.some((selectedUser) => !selectedUser.user.blocked);
      const onlyUserToBlock =
        onlyCheckedUser ||
        (isBlock
          ? checkedUsers.find(({ user }) => !user.blocked)
          : checkedUsers.find(({ user }) => user.blocked));

      if (allUsersChecked || !!uncheckedUsers.length) {
        if (isBlock)
          await blockUsers({
            all: true,
            unchecked_ids: [1, ownerId, ...uncheckedUsers.map(({ user }) => user.id)],
            search_string: searchValue,
          }).unwrap();
        else
          await unblockUsers({
            all: true,
            unchecked_ids: uncheckedUsers.map(({ user }) => user.id),
            search_string: searchValue,
          }).unwrap();
      } else if (checkedUsers.length) {
        if (isBlock) {
          const activeUsersIds = checkedUsers
            .filter(({ user }) => !user.blocked)
            .map(({ user }) => user.id);
          await blockUsers({ checked_ids: activeUsersIds }).unwrap();
        } else {
          await unblockUsers({ checked_ids: checkedUsers.map(({ user }) => user.id) }).unwrap();
        }
      }

      dispatch(
        setNotice({
          id: Math.random(),
          isRead: false,
          message:
            usersToBlockCount > 1
              ? `${
                  usersToBlockCount +
                  ' ' +
                  getDeclinationByNumber(usersToBlockCount, [
                    'пользователь',
                    'пользователя',
                    'пользователей',
                  ])
                } ${
                  (isBlock ? 'заблокирован' : 'разблокирован') +
                  (checkForNumberEnding(usersToBlockCount) ? '' : 'ы')
                } в ${PROJECT_NAME}.`
              : `Пользователь ${onlyUserToBlock?.user.nickname || ''} ${
                  isBlock ? 'заблокирован' : 'разблокирован'
                } в ${PROJECT_NAME}.`,
          timestamp: new Date().toString(),
          avatar: usersToBlockCount > 1 ? null : onlyUserToBlock?.user.picture,
        }),
      );

      clearUsers();
    } catch (e) {
      console.log('handleBlockUsers error:', e);
    }
  };

  const handleDeleteUsers = async () => {
    try {
      const onlyCheckedUser = getOnlyCheckedUser();

      await deleteUsers({
        delete_apps_with_user: deleteAppsWithUser,
        apps_ids: deleteAppsWithUser
          ? deleteUsersInfoData?.apps.map(({ client }) => client.client_id)
          : deleteUsersInfoData?.apps
              .filter((app) => !app.onlyEditor)
              .map(({ client }) => client.client_id),
        checked_ids: usersToDelete,
      }).unwrap();

      dispatch(
        setNotice({
          id: Math.random(),
          isRead: false,
          message:
            checkedUsersCount > 1
              ? `${
                  checkedUsersCount +
                  ' ' +
                  getDeclinationByNumber(checkedUsersCount, [
                    'пользователь',
                    'пользователя',
                    'пользователей',
                  ])
                } ${
                  checkForNumberEnding(checkedUsersCount) ? 'удален' : 'удалено'
                } в ${PROJECT_NAME}.`
              : `Пользователь ${onlyCheckedUser?.user.nickname} удален в ${PROJECT_NAME}.`,
          timestamp: new Date().toString(),
          avatar: checkedUsersCount > 1 ? null : onlyCheckedUser?.user.picture,
        }),
      );

      clearUsers(true);
      refetchUsersCount();
    } catch (e) {
      console.log('handleDeleteUsers error:', e);
    }
  };

  const handleDeleteButton = async () => {
    try {
      await getDeleteUsersInfo(
        allUsersChecked || !!uncheckedUsers.length
          ? {
              all: true,
              unchecked_ids: uncheckedUsers.map(({ user }) => user.id),
              search_string: searchValue,
            }
          : { checked_ids: checkedUsers.map(({ user }) => user.id) },
      ).unwrap();
      setDeleteUsersModalOpen(true);
    } catch (e) {
      console.log('handleDeleteButton error:', e);
    }
  };

  const handleChangeRoleButton = async () => {
    try {
      const userCount = await getUserCount({
        selectedAppId: CLIENT_ID,
        ignore_ids: [1, ownerId, ...uncheckedUsers.map(({ user }) => user.id)],
        search_string: searchValue,
      }).unwrap();
      setUsersCountState(userCount || null);
      setSelectedPermission(getCurrentRole(userCount));
      setChangePermissionsModalOpen(true);
    } catch (e) {
      console.log('handleChangeRoleButton error:');
    }
  };

  const getCurrentRole = (userCount: TUserCount) => {
    if (checkedUsers.length) {
      if (checkedUsers.find(({ role }) => !isAdministrator(role))) return Roles.USER;
      if (checkedUsers.find(({ role }) => role === Roles.ADMIN)) return Roles.ADMIN;
      if (checkedUsers.every(({ role }) => isOwner(role))) return Roles.OWNER;
    }

    if (userCount) {
      if (userCount.adminCount + userCount.ownerCount < userCount.userCount) return Roles.USER;
      if (
        !!userCount.adminCount &&
        userCount.adminCount + userCount.ownerCount === userCount.userCount
      )
        return Roles.ADMIN;
      if (userCount.ownerCount === userCount.userCount) return Roles.OWNER;
    }
  };

  const radioButtonSelected = (role: Roles.USER | Roles.ADMIN | Roles.OWNER) => {
    return selectedPermission === role;
  };

  const blockButton = (inPopover?: boolean) =>
    inPopover ? (
      <CustomPopoverButton
        disabled={getActiveUsersCountLoading}
        startIcon={<LockIcon className={styles['popover-button-icon']} />}
        onClick={() => setBlockUsersModalOpen(true)}
      >
        {haveActiveUsers ? 'Заблокировать' : 'Разблокировать'}
      </CustomPopoverButton>
    ) : (
      <Button
        disabled={getActiveUsersCountLoading}
        className={styles['users-operation-button']}
        color="secondary"
        startIcon={<LockIcon />}
        variant="custom"
        onClick={() => setBlockUsersModalOpen(true)}
      >
        {haveActiveUsers ? 'Заблокировать' : 'Разблокировать'}
      </Button>
    );

  const deleteSessionsButton = (inPopover?: boolean) =>
    inPopover ? (
      <CustomPopoverButton
        startIcon={<QuitIcon className={styles['popover-button-icon']} />}
        onClick={() => setDeleteUsersSessionsModalOpen(true)}
      >
        Завершить все сеансы
      </CustomPopoverButton>
    ) : (
      <Button
        className={styles['users-operation-button']}
        color="secondary"
        startIcon={<QuitIcon />}
        variant="custom"
        onClick={() => setDeleteUsersSessionsModalOpen(true)}
      >
        Завершить все сеансы
      </Button>
    );

  const changePermissionsButton = (inPopover?: boolean) =>
    inPopover ? (
      <CustomPopoverButton
        startIcon={
          getUsersCountFetching ? (
            <RefreshIcon className={styles['popover-button-refresh-icon']} />
          ) : (
            <OwnerIcon className={styles['popover-button-icon']} />
          )
        }
        disabled={getUsersCountFetching}
        onClick={handleChangeRoleButton}
      >
        Изменить права
      </CustomPopoverButton>
    ) : (
      <CustomButton
        className={styles['users-operation-button']}
        color="secondary"
        startIcon={<OwnerIcon />}
        disabled={getUsersCountFetching}
        loading={getUsersCountFetching}
        onClick={handleChangeRoleButton}
      >
        Изменить права
      </CustomButton>
    );

  const deleteButton = (inPopover?: boolean) =>
    inPopover ? (
      <CustomPopoverButton
        startIcon={
          deleteUsersInfoLoading ? (
            <RefreshIcon className={styles['popover-button-refresh-icon']} />
          ) : (
            <BasketIcon className={styles['popover-button-icon']} />
          )
        }
        disabled={deleteUsersInfoLoading}
        onClick={handleDeleteButton}
      >
        Удалить
      </CustomPopoverButton>
    ) : (
      <CustomButton
        className={styles['users-operation-button']}
        refreshIconClassName={styles['load-icon-black']}
        color="secondary"
        startIcon={<BasketIcon />}
        onClick={handleDeleteButton}
        disabled={deleteUsersInfoLoading}
        loading={deleteUsersInfoLoading}
      >
        Удалить
      </CustomButton>
    );

  const changePermissionsModal = () => {
    const usersToChangeRoleCount = getUsersToChangeRoleCount() || 0;
    const onlyUserToChangeRole = getOnlyUserToChangeRole()?.user;

    return (
      <Modal open={changePermissionsModalOpen} onClose={closeChangePermissionModal}>
        <div className={styles['modal-wrapper']}>
          <div style={{ display: 'flex', marginBottom: 24 }}>
            <CustomTypography className={clsx('header-2-medium', 'font-golos')}>
              Изменить права{' '}
              {usersToChangeRoleCount > 1 && `пользователей (${usersToChangeRoleCount})`}
            </CustomTypography>
            <IconButton
              onClick={closeChangePermissionModal}
              style={{ marginLeft: 'auto', padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <CustomTypography style={{ marginBottom: 16 }} className={clsx('text-14')}>
            Выберите права в {PROJECT_NAME} для{' '}
            {usersToChangeRoleCount > 1 ? 'пользователей' : 'пользователя'}:
          </CustomTypography>
          {usersToChangeRoleCount === 1 && onlyUserToChangeRole && (
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 32 }}>
              <Avatar src={getImageURL(onlyUserToChangeRole.picture)} className={styles.avatar}>
                {!onlyUserToChangeRole.picture && (
                  <CustomTypography color="background" className={clsx('text-14')}>
                    {onlyUserToChangeRole.nickname
                      ?.split(' ')
                      .map((name: string) => name[0]?.toUpperCase())
                      .join('')}
                  </CustomTypography>
                )}
              </Avatar>
              <CustomTypography className={clsx('text-14')}>
                {onlyUserToChangeRole.nickname}
              </CustomTypography>
            </div>
          )}
          <CustomTypography style={{ marginBottom: 16 }} className={clsx('text-15-medium')}>
            Уровень полномочий
          </CustomTypography>
          {!dataSettings?.allow_everyone_to_create_applications && (
            <>
              <CustomRadioButton
                label="Участник"
                checked={radioButtonSelected(Roles.USER)}
                onClick={() => {
                  setSelectedPermission(Roles.USER);
                }}
              />
              <CustomTypography
                style={{ marginLeft: 40, marginBottom: 16 }}
                className={clsx('text-12')}
                color="grey"
              >
                Имеет доступ в приложение и может просматривать профиль приложения
              </CustomTypography>
            </>
          )}
          <CustomRadioButton
            label="Администратор личного кабинета"
            checked={radioButtonSelected(Roles.ADMIN)}
            onClick={() => {
              setSelectedPermission(Roles.ADMIN);
            }}
          />
          <CustomTypography
            style={{ marginLeft: 40, marginBottom: 32 }}
            className={clsx('text-12')}
            color="grey"
          >
            Может создавать свои приложения, управлять полномочиями участников своих приложений,
            просматривать персональные данные участников своих приложений
          </CustomTypography>
          <CustomRadioButton
            label="Владелец личного кабинета"
            checked={radioButtonSelected(Roles.OWNER)}
            onClick={() => {
              setSelectedPermission(Roles.OWNER);
            }}
          />
          <CustomTypography
            style={{ marginLeft: 40, marginBottom: 32 }}
            className={clsx('text-12')}
            color="grey"
          >
            Имеет полный контроль над всеми пользователями и приложениями
          </CustomTypography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={disableButtons}
              variant="custom"
              color="secondary"
              onClick={closeChangePermissionModal}
            >
              Отмена
            </Button>
            <CustomButton
              loading={disableButtons}
              disabled={disableButtons || !usersToChangeRoleCount}
              onClick={handleSaveRole}
              style={{ marginLeft: 24 }}
            >
              Сохранить
            </CustomButton>
          </div>
        </div>
      </Modal>
    );
  };

  const deleteUsersSessionsModal = () => {
    const onlyCheckedUser = getOnlyCheckedUser();

    return (
      <Modal
        open={deleteUsersSessionsModalOpen}
        onClose={() => setDeleteUsersSessionsModalOpen(false)}
      >
        <div className={styles['modal-wrapper']}>
          <div style={{ display: 'flex', marginBottom: 24 }}>
            <CustomTypography className={clsx('header-2-medium', 'font-golos')}>
              Завершить сеансы {checkedUsersCount > 1 && `пользователей (${checkedUsersCount})`}
            </CustomTypography>
            <IconButton
              disabled={disableButtons}
              onClick={() => setDeleteUsersSessionsModalOpen(false)}
              style={{ marginLeft: 'auto', padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <CustomTypography style={{ marginBottom: 40 }} className={clsx('text-14')}>
            {checkedUsersCount > 1
              ? 'Завершить сеансы пользователей во всех приложениях на всех устройствах?'
              : `Завершить сеансы ${onlyCheckedUser?.user.nickname} во всех приложениях на всех устройствах?`}
          </CustomTypography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={disableButtons}
              variant="custom"
              color="secondary"
              onClick={() => setDeleteUsersSessionsModalOpen(false)}
            >
              Отмена
            </Button>
            <CustomButton
              loading={deleteUsersSessionsLoading}
              disabled={deleteUsersSessionsLoading}
              style={{ marginLeft: 24 }}
              onClick={handleDeleteUsersSessions}
            >
              Завершить
            </CustomButton>
          </div>
        </div>
      </Modal>
    );
  };

  const deleteUsersModal = () => {
    const onlyUserToDelete =
      usersToDelete.length === 1
        ? users.find((user) => user?.user.id === usersToDelete[0])?.user
        : null;
    if (!usersToDelete.length) return;

    return (
      <Modal open={deleteUsersModalOpen} onClose={() => setDeleteUsersModalOpen(false)}>
        <div className={styles['modal-wrapper']}>
          <CustomTypography
            style={{ marginBottom: 24 }}
            className={clsx('header-2-medium', 'font-golos')}
          >
            {usersToDelete.length > 1
              ? `Удалить аккаунты (${usersToDelete.length})`
              : `Удалить аккаунт ${PROJECT_NAME}`}
          </CustomTypography>
          <div>
            <CustomTypography className={clsx('text-14')}>
              {usersToDelete.length > 1
                ? `Аккаунты будут удалены. Данные, связанные с аккаунтами, удалятся навсегда.
        Пользователи потеряют доступ к приложениям, в которых для входа использовали аккаунт ${PROJECT_NAME}.`
                : `Аккаунт ${onlyUserToDelete?.nickname} будет удален. Пользователь потеряет доступ к приложениям, в которых для входа использовал аккаунт ${PROJECT_NAME}.`}
            </CustomTypography>
            {!!deleteUsersInfoData?.apps.length && (
              <CustomTypography style={{ marginTop: 16 }} className={clsx('text-14')}>
                {usersToDelete.length > 1
                  ? `Вместе с аккаунтами будут удалены приложения без участников, в которых удаляемые
          пользователи являются единственными администраторами.`
                  : 'Вместе с аккаунтом будут удалены приложения без участников, в которых удаляемый пользователь является единственным администратором.'}
              </CustomTypography>
            )}
            {!!applicationsToDelete.length && (
              <>
                <CustomTypography style={{ marginTop: 16 }} className={clsx('text-14')}>
                  Выберите, что нужно сделать с приложениями с участниками, в которых удаляемый
                  пользователь является единственным администратором:
                </CustomTypography>
                <div style={{ marginTop: 10 }}>
                  <CustomRadioButton
                    label="Удалить только аккаунты"
                    className={styles['radio-delete-users']}
                    onClick={() => setDeleteAppsWithUser(false)}
                    checked={!deleteAppsWithUser}
                  />
                  <CustomRadioButton
                    label=" Удалить аккаунты и все приложения, которыми они управляют"
                    className={styles['radio-delete-apps']}
                    onClick={() => setDeleteAppsWithUser(true)}
                    checked={deleteAppsWithUser}
                  />
                </div>
              </>
            )}
          </div>
          {!!applicationsToDelete.length && (
            <div className={styles['apps-to-delete']}>
              {applicationsToDelete.map((app) => (
                <div key={app.client_id} className={styles['app-to-delete']}>
                  <div
                    style={{
                      backgroundImage: `url(${BACKEND_URL + '/' + app.avatar})`,
                    }}
                    className={styles['app-icon']}
                  >
                    {!app.avatar && <AppIcon fill="#ced0d9" />}
                  </div>
                  <Link className={styles['app-link']} to={`/applications/${app.client_id}`}>
                    {app.name}
                  </Link>
                </div>
              ))}
            </div>
          )}
          <div style={{ marginTop: 32, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={disableButtons}
              onClick={() => setDeleteUsersModalOpen(false)}
              variant="custom"
              color="secondary"
            >
              Отмена
            </Button>
            <CustomButton
              loading={disableButtons}
              disabled={disableButtons}
              onClick={handleDeleteUsers}
              color="error"
              style={{ marginLeft: 24 }}
            >
              {usersToDelete.length > 1 ? ' Удалить аккаунты' : 'Удалить аккаунт'}
            </CustomButton>
          </div>
        </div>
      </Modal>
    );
  };

  const blockUsersModal = () => (
    <Modal open={blockUsersModalOpen} onClose={() => setBlockUsersModalOpen(false)}>
      <div className={styles['modal-wrapper']}>
        <div style={{ display: 'flex', marginBottom: 24 }}>
          <CustomTypography className={clsx('header-2-medium', 'font-golos')}>
            {haveActiveUsers ? 'Заблокировать ' : 'Разблокировать '}
            {usersToBlockCount === 1 ? 'пользователя' : `пользователей (${usersToBlockCount})`}
          </CustomTypography>
          <IconButton
            disabled={disableButtons}
            onClick={() => setBlockUsersModalOpen(false)}
            style={{ marginLeft: 'auto', padding: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <CustomTypography style={{ marginBottom: 40 }} className={clsx('text-14')}>
          {haveActiveUsers
            ? usersToBlockCount > 1
              ? `Пользователи потеряют доступ к приложениям, в которых для входа использовали аккаунт
              ${PROJECT_NAME}. Данные, связанные с аккаунтами, будут сохранены.`
              : `Пользователь потеряет доступ к приложениям, в которых
              для входа использовал аккаунт ${PROJECT_NAME}. Данные, связанные
              с аккаунтом, будут сохранены.`
            : usersToBlockCount > 1
            ? `Пользователи будут разблокированы и снова получат доступ к аккаунтам ${PROJECT_NAME}.`
            : `Пользователь будет разблокирован и снова получит доступ к аккаунту ${PROJECT_NAME}.`}
        </CustomTypography>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            disabled={disableButtons}
            variant="custom"
            color="secondary"
            onClick={() => setBlockUsersModalOpen(false)}
          >
            Отмена
          </Button>
          <CustomButton
            loading={disableButtons}
            disabled={disableButtons}
            style={{ marginLeft: 24 }}
            onClick={handleBlockUsers}
          >
            {haveActiveUsers ? 'Заблокировать' : 'Разблокировать'}
          </CustomButton>
        </div>
      </div>
    </Modal>
  );

  return (
    <div
      className={styles.wrapper}
      style={{
        width: width - 1,
      }}
    >
      <CustomCheckbox
        checked={allUsersChecked || checkedUsers.length === users.length}
        indeterminate={
          checkedUsers.length !== users.length && (!!checkedUsers.length || !!uncheckedUsers.length)
        }
        onClick={handleCheckAllUsers}
        className={styles.checkbox}
      />
      <CustomTypography
        style={{ marginLeft: 16, whiteSpace: 'nowrap' }}
        className={clsx('text-14')}
        color="grey"
      >
        Выбрано: {Math.max(0, checkedUsersCount)}
      </CustomTypography>
      <div className={styles['users-operation-buttons-wrapper']}>
        {operationsCount >= 1 && blockButton()}
        {operationsCount >= 2 && deleteSessionsButton()}
        {operationsCount >= 3 && changePermissionsButton()}
        {operationsCount >= 4 && deleteButton()}
        {operationsCount < 4 && (
          <>
            <Popover
              onClose={(e: MouseEvent<ReactElement>) => {
                e.stopPropagation();
                setOperationsPopoverAnchorEl(null);
              }}
              classes={{
                paper: styles['popover-paper'],
              }}
              anchorEl={operationsPopoverAnchorEl}
              open={!!operationsPopoverAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {operationsCount < 1 && blockButton(true)}
              {operationsCount < 2 && deleteSessionsButton(true)}
              {operationsCount < 3 && changePermissionsButton(true)}
              {deleteButton(true)}
            </Popover>
            <IconButton
              onClick={(e) => setOperationsPopoverAnchorEl(e.currentTarget)}
              className={styles['operations-icon']}
              style={{
                marginLeft: 16,
              }}
            >
              <ActionsIcon />
            </IconButton>
          </>
        )}
      </div>
      {changePermissionsModal()}
      {deleteUsersSessionsModal()}
      {deleteUsersModal()}
      {blockUsersModal()}
    </div>
  );
};
