import React from 'react';
import Select, { SelectProps } from '@mui/material/Select';
import { ReactComponent as ArrowDropDownIcon } from '../../icons/ArrowDropDown.svg';
import styles from './CustomSelect.module.css';
import clsx from 'clsx';
import { useTheme } from '@mui/material';

export const CustomSelect: <T>(props: Omit<SelectProps<T>, 'variant'>) => JSX.Element = ({
  className,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Select
      IconComponent={ArrowDropDownIcon}
      MenuProps={{ classes: { paper: styles.menu } }}
      className={clsx(className, styles.select)}
      classes={{ outlined: styles.outlined }}
      sx={{
        '& .MuiSelect-select': {
          color: theme.palette.custom.main,
        },
        '&:hover fieldset': {
          borderColor: `${theme.palette.custom.main} !important`,
        },
        '&.Mui-focused fieldset': {
          borderColor: `${theme.palette.custom.main} !important`,
        },
        '&.Mui-error fieldset': {
          borderColor: theme.palette.custom.error,
        },
        '& .MuiSelect-icon path': {
          fill: theme.palette.custom.mainGrey,
        },
      }}
      {...props}
    />
  );
};
