import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { sendAuthRequest } from '../requests/oidc';

type TLoginProps = Record<string, unknown>;

const mapStateToProps = () => ({});

const LoginComponent: React.FC<TLoginProps> = () => {
  useEffect(() => {
    sendAuthRequest('consent');
  }, []);

  return <></>;
};

export const Login = connect(mapStateToProps)(LoginComponent);
