import Button from '@mui/material/Button';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { convertUserProfile } from '../../helpers';
import { useRestoreProfileMutation } from '../../redux/services/user';
import { RootState } from '../../redux/store';
import { setUserProfile, TUserProfile } from '../../redux/userSlice';
import { getUserProfile } from '../../requests/oidc';
import { useNavigate } from 'react-router-dom-v5-compat';

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
  deleted: state.user.userProfile.deleted,
});

export const RestoreProfileComponent = ({
  userId,
  deleted,
}: {
  userId: TUserProfile['id'];
  deleted: TUserProfile['deleted'];
}) => {
  const [restoreProfile] = useRestoreProfileMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (userId && !deleted) {
      return navigate('/profile');
    }
  }, [userId]);

  const handleRestoreProfile = async () => {
    if (userId) {
      await restoreProfile({ userId: userId });
      const profile = await getUserProfile();
      if (profile) {
        const convertedProfile = convertUserProfile(profile);
        dispatch(setUserProfile(convertedProfile));
        return navigate('/profile');
      }
    }
  };
  return (
    <Button
      style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '32px', height: 'fit-content' }}
      onClick={handleRestoreProfile}
      variant="custom2"
    >
      Восстановить аккаунт
    </Button>
  );
};

export const RestoreProfile = connect(mapStateToProps)(RestoreProfileComponent);
