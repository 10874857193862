import clsx from 'clsx';
import React, { FC } from 'react';
import styles from './Modal.module.css';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { CustomTypography } from '../custom/CustomTypography';

interface IModalWithAction {
  isOpen: boolean;
  onAction: () => void;
  onClose: () => void;
  title: string;
  message: string;
  actionTitle: string;
}

export const ModalWithAction: FC<IModalWithAction> = ({
  isOpen,
  onAction,
  onClose,
  title,
  message,
  actionTitle,
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className={styles['modal-container']}>
        <div style={{ display: 'flex' }}>
          <CustomTypography className={clsx('header-2-medium', 'font-golos')}>
            {title}
          </CustomTypography>
          <IconButton onClick={onClose} style={{ marginLeft: 'auto', marginBottom: 16 }}>
            <CloseIcon />
          </IconButton>
        </div>
        <CustomTypography style={{ marginBottom: 32 }} className={clsx('text-14')}>
          {message}
        </CustomTypography>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="custom" color="secondary" onClick={onClose}>
            Отмена
          </Button>
          <Button onClick={onAction} variant="custom" style={{ marginLeft: 24 }}>
            {actionTitle}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
