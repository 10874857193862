import React, { useState, FC } from 'react';
import clsx from 'clsx';
import styles from './BindAccount.module.css';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { useBindAccountMutation } from '../../redux/services/user';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { CustomTypography } from '../custom/CustomTypography';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as EyeIcon } from '../../icons/Eye.svg';
import { ReactComponent as EyeOffIcon } from '../../icons/EyeOff.svg';

export const BindIdmAccount: FC = () => {
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [bindError, setBindError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { provider_id = '' } = useParams<{ provider_id: string }>();
  const navigate = useNavigate();
  const userId = useSelector((state: RootState) => state.user.userProfile.id);
  const [bindIdmAccount, { isLoading: bindIdmAccountLoading }] = useBindAccountMutation();

  const handleAddIdmAccount = async () => {
    try {
      setPasswordError('');
      setUsernameError('');
      if (!username) return setUsernameError('Введите логин');
      if (!password) return setPasswordError('Введите пароль');

      if (userId) {
        const bindIdmAccountResponse = await bindIdmAccount({
          userId,
          provider_id,
          username,
          password,
          rebind: !!bindError,
        }).unwrap();

        if (!bindIdmAccountResponse.success)
          return setBindError(`Аккаунт IDM уже привязан к ${bindIdmAccountResponse.nickname}`);
        navigate('/profile');
      }
    } catch (e) {
      console.log(handleAddIdmAccount.name + ' error:', e);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Button
        onClick={() => navigate('/profile')}
        variant="custom3"
        className={clsx('text-15', styles['button-back'])}
        startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
      >
        Профиль
      </Button>
      <div className={styles['change-email']}>
        <CustomTypography
          style={{ marginBottom: 24 }}
          className={clsx('text-24-medium', 'font-golos')}
        >
          Добавить аккаунт IDM
        </CustomTypography>
        <CustomTypography style={{ marginBottom: 8 }} className={clsx('text-14')}>
          Логин
        </CustomTypography>
        <TextField
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className={clsx('custom', styles.textfield)}
          fullWidth
          variant="standard"
          error={!!usernameError}
          helperText={usernameError}
        />
        <CustomTypography style={{ marginBottom: 8 }} className={clsx('text-14')}>
          Пароль
        </CustomTypography>
        <TextField
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={clsx('custom', styles.textfield)}
          type={showPassword ? 'text' : 'password'}
          fullWidth
          variant="standard"
          error={!!passwordError || !!bindError}
          helperText={passwordError || bindError}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  placement="bottom-end"
                  arrow
                  title={showPassword ? 'Скрыть' : 'Показать'}
                  classes={{
                    tooltip: styles['input-tooltip'],
                    arrow: styles['input-tooltip-arrow'],
                  }}
                >
                  <>
                    <IconButton
                      className={styles['input-adornment-button']}
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? <EyeOffIcon /> : <EyeIcon />}
                    </IconButton>
                  </>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 48 }}>
          <Button onClick={() => navigate('/profile')} variant="custom" color="secondary">
            Отмена
          </Button>
          <Button
            disabled={bindIdmAccountLoading}
            onClick={handleAddIdmAccount}
            variant="custom"
            style={{ marginLeft: 24 }}
          >
            {bindIdmAccountLoading ? (
              <CircularProgress size={24} style={{ color: 'white' }} />
            ) : (
              !bindIdmAccountLoading && (bindError ? 'Перепривязать' : 'Добавить')
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};
