import clsx from 'clsx';
import React, { FC } from 'react';
import { TApplication } from '../../redux/services/client';
import { IconWrapper } from '../IconWrapper';
import styles from './ApplicationInfoProfile.module.css';
import { ReactComponent as UrlIcon } from '../../icons/Url.svg';
import { ReactComponent as WatchIcon } from '../../icons/Watch.svg';
import { ReactComponent as InfoIcon } from '../../icons/Info.svg';
import { ReactComponent as CalendarIcon } from '../../icons/Calendar2.svg';
import { createFullDateString, isOwnerOrEditor } from '../../helpers';
import { Link } from '@mui/material';
import { getScopeProps } from '../../utils/getScopeProps';
import { CustomTypography } from '../custom/CustomTypography';

type TApplicationInfoProfileProps = {
  application?: TApplication;
  scopesData?: { scopes?: string; createdAt?: string };
};

export const ApplicationInfoProfile: FC<TApplicationInfoProfileProps> = ({
  application,
  scopesData,
}) => {
  const { scopes, createdAt } = scopesData || {};
  return (
    <div
      className={clsx(styles.wrapper, {
        [styles['admin-wrapper']]: isOwnerOrEditor(application?.role),
      })}
    >
      <div className={styles.info}>
        <CustomTypography className={clsx('header-3', 'font-golos', styles['info-title'])}>
          Информация о приложении
        </CustomTypography>
        {application?.client?.description && (
          <div className={styles['flex-container']}>
            <IconWrapper>
              <InfoIcon className={styles['info-icon']} />
            </IconWrapper>
            <div className={styles['client-description-wrapper']}>
              <CustomTypography
                className={clsx(
                  'text-14',

                  styles['info-item'],
                  styles['client-description'],
                )}
              >
                {application.client?.description}
              </CustomTypography>
            </div>
          </div>
        )}

        {application?.client?.domain && (
          <div className={clsx(styles['flex-container'], styles['align-center'])}>
            <IconWrapper>
              <UrlIcon />
            </IconWrapper>
            <Link
              href={application.client?.domain}
              underline="hover"
              variant="link"
              className={clsx('text-14', styles['info-item'])}
            >
              {application.client?.domain}
            </Link>
          </div>
        )}
        {createdAt && (
          <div className={clsx(styles['flex-container'], styles['align-center'])}>
            <IconWrapper>
              <WatchIcon />
            </IconWrapper>
            <CustomTypography className={clsx('text-14', styles['info-item'])}>
              Доступ предоставлен {createFullDateString(new Date(createdAt))}
            </CustomTypography>
          </div>
        )}
        {isOwnerOrEditor(application?.role) && (
          <>
            {application?.client.created_at && (
              <div className={clsx(styles['flex-container'], styles['align-center'])}>
                <IconWrapper>
                  <CalendarIcon />
                </IconWrapper>
                <CustomTypography className={clsx('text-14')}>
                  Создано {createFullDateString(new Date(application.client.created_at))}
                </CustomTypography>
              </div>
            )}
          </>
        )}
      </div>
      {scopes && (
        <div className={styles.info}>
          <CustomTypography className={clsx('header-3', 'font-golos', styles['info-title'])}>
            Предоставленные разрешения
          </CustomTypography>
          {scopes?.split(' ').map((scope, index) => {
            const scopeProps = getScopeProps(scope);
            return (
              scopeProps && (
                <div key={index} className={clsx(styles['flex-container'], styles['align-center'])}>
                  <div className={styles['info-icon-wrapper']}>
                    <scopeProps.icon />
                  </div>
                  <CustomTypography className={clsx('text-14', styles['info-item'])}>
                    {scopeProps.description}
                  </CustomTypography>
                </div>
              )
            );
          })}
        </div>
      )}
    </div>
  );
};
