import React, { FC } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../redux/store';
import { setIsEventLogFilterOpen, TAppSlice } from '../redux/appSlice';
import { setFilter, setFilterCount, TFilter } from '../redux/userSlice';
import styles from './EventLogFilter.module.css';
import clsx from 'clsx';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ruLocale from 'date-fns/locale/ru';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
registerLocale('ru', ruLocale);
import { isObjectEmpty } from '../helpers';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { ReactComponent as CloseIcon } from '../icons/Close.svg';
import { CustomSelect } from './custom/CustomSelect';
import { CustomTypography } from './custom/CustomTypography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';

const schema = yup.object({
  start_date: yup
    .date()
    .max(new Date(), 'Дата начала не может быть позднее сегодняшнего дня')
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr)),
  end_date: yup
    .date()
    .min(yup.ref('start_date'), 'Дата начала превышает дату окончания')
    .max(new Date(), 'Дата окончания не может быть позднее сегодняшнего дня')
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr)),
  client_id: yup.string().matches(/^[^\n ]*$/, {
    message: 'Поле Client_id не может содержать пробелы',
  }),
  user_id: yup.string().matches(/^[0-9]*$/, {
    message: 'Поле User_id должно состоять из цифр',
  }),
  ip_address: yup.string().matches(/^[^\n ]*$/, {
    message: 'Поле IP не может содержать пробелы',
  }),
  event: yup.string(),
  description: yup.string(),
});

type TEventLogFilterProps = {
  isEventLogFilterOpen: TAppSlice['isEventLogFilterOpen'];
  role: string | undefined;
};

const mapStateToProps = (state: RootState) => ({
  isEventLogFilterOpen: state.app.isEventLogFilterOpen,
});

const EventLogFilterComponent: FC<TEventLogFilterProps> = ({ isEventLogFilterOpen, role }) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    control,
    formState: { errors },
    clearErrors,
  } = useForm<TFilter>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      level: 'ALL',
      start_date: '',
      end_date: '',
    },
  });
  const theme = useTheme();

  const levelVariants = [
    {
      name: 'Все',
      value: 'ALL',
    },
    {
      name: 'Информация',
      value: 'INFO',
    },
    {
      name: 'Ошибка',
      value: 'ERROR',
    },
  ];

  const watchFields = watch([
    'level',
    'start_date',
    'end_date',
    'client_id',
    'user_id',
    'ip_address',
    'event',
    'description',
  ]);
  const selectedFilters = watchFields.filter((item) => {
    return !(item === '' || item === undefined || item === 'ALL');
  });
  const watchLevel = watch('level');
  const watchStartDate = watch('start_date');
  const watchEndDate = watch('end_date');

  const dispatch = useDispatch();
  const closeDrawer = () => {
    dispatch(setIsEventLogFilterOpen(false));
  };

  const onSubmit: SubmitHandler<TFilter> = (data) => {
    const formData = {
      ...data,
      start_date: data.start_date ? new Date(data.start_date).toISOString() : '',
      end_date: data.end_date ? new Date(data.end_date).toISOString() : '',
    };
    dispatch(setFilter(formData));
  };

  return (
    <Drawer
      classes={{ paper: styles['drawer-paper'] }}
      open={isEventLogFilterOpen}
      anchor="right"
      onClose={closeDrawer}
      BackdropProps={{ invisible: true }}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
        <div className={styles.header}>
          <CustomTypography className={clsx('header-3', 'font-golos')}>Фильтрация</CustomTypography>
          <IconButton
            sx={(theme) => ({
              '& path': {
                stroke: theme.palette.custom.main,
              },
            })}
            onClick={closeDrawer}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Box
          sx={{
            [`& .${styles['date-picker__error']}`]: {
              borderColor: theme.palette.custom.error,
            },
            [`& .${styles['date-picker__error']}:focus`]: {
              borderColor: `${theme.palette.custom.error} !important`,
            },
          }}
          className={styles['filter-form']}
        >
          <CustomTypography style={{ marginBottom: 8 }} className={clsx('text-14')}>
            Уровень
          </CustomTypography>
          <CustomSelect value={watchLevel} onChange={(e) => setValue('level', e.target.value)}>
            {levelVariants.map((variant) => (
              <MenuItem className="custom-select" key={variant.value} value={variant.value}>
                {variant.name}
              </MenuItem>
            ))}
          </CustomSelect>
          <CustomTypography className={clsx('text-14', styles['input-title'])}>
            Дата и время
          </CustomTypography>
          <Controller
            control={control}
            name="start_date"
            defaultValue=""
            render={({ field }) => (
              <DatePicker
                selected={field.value ? new Date(field.value) : null}
                onChange={(newValue) => field.onChange(newValue?.toISOString())}
                maxDate={watchEndDate ? new Date(watchEndDate as string) : new Date()}
                dateFormat="dd.MM.yyyy p"
                locale="ru"
                placeholderText="дд.мм.гггг   00:00"
                showTimeInput
                timeInputLabel="Время:"
                strictParsing={true}
                className={clsx(styles['date-picker'], {
                  [styles['date-picker__error']]: errors.start_date,
                })}
              />
            )}
          />
          <Controller
            control={control}
            name="end_date"
            render={({ field }) => (
              <DatePicker
                selected={field.value ? new Date(field.value) : null}
                onChange={(newValue) => field.onChange(newValue?.toISOString())}
                minDate={new Date(watchStartDate as string)}
                maxDate={new Date()}
                dateFormat="dd.MM.yyyy p"
                locale="ru"
                placeholderText="дд.мм.гггг   00:00"
                showTimeInput
                timeInputLabel="Время:"
                className={clsx(styles['date-picker'], {
                  [styles['date-picker__error']]: errors.end_date,
                })}
              />
            )}
          />
          {(errors.start_date || errors.end_date) && (
            <CustomTypography className={styles['error-message']}>
              {errors?.start_date?.message || errors?.end_date?.message}
            </CustomTypography>
          )}
          <CustomTypography className={clsx('text-14', styles['input-title'])}>
            Приложение (Client_id)
          </CustomTypography>
          <TextField
            {...register('client_id', {
              onChange: () => {
                if (errors.client_id) clearErrors('client_id');
              },
            })}
            className="custom"
            error={!!errors.client_id}
            helperText={errors.client_id ? errors.client_id.message : ''}
            fullWidth
            variant="standard"
          />
          {role !== '' && (
            <>
              <CustomTypography className={clsx('text-14', styles['input-title'])}>
                Пользователь (User_id)
              </CustomTypography>
              <TextField
                {...register('user_id', {
                  onChange: () => {
                    if (errors.user_id) clearErrors('user_id');
                  },
                })}
                className="custom"
                error={!!errors.user_id}
                helperText={errors.user_id ? errors.user_id.message : ''}
                fullWidth
                variant="standard"
              />
            </>
          )}
          <CustomTypography className={clsx('text-14', styles['input-title'])}>
            Местоположение (IP)
          </CustomTypography>
          <TextField
            {...register('ip_address', {
              onChange: () => {
                if (errors.ip_address) clearErrors('ip_address');
              },
            })}
            className="custom"
            error={!!errors.ip_address}
            helperText={errors.ip_address ? errors.ip_address.message : ''}
            fullWidth
            variant="standard"
          />
          <CustomTypography className={clsx('text-14', styles['input-title'])}>
            Событие
          </CustomTypography>
          <TextField {...register('event')} className="custom" fullWidth variant="standard" />
          <CustomTypography className={clsx('text-14', styles['input-title'])}>
            Описание
          </CustomTypography>
          <TextField {...register('description')} className="custom" fullWidth variant="standard" />
        </Box>
        <div className={styles['bottom-buttons']}>
          <Button
            style={{ marginRight: 16, paddingRight: 16, whiteSpace: 'nowrap', color: '#0B1641' }}
            variant="custom"
            color="secondary"
            disabled={!selectedFilters.length}
            onClick={() => {
              reset();
            }}
          >
            Сбросить фильтр
            {selectedFilters.length ? (
              <Box
                sx={{
                  background: theme.palette.custom.error,
                }}
                className={styles.count}
              >
                {selectedFilters.length}
              </Box>
            ) : (
              ''
            )}
          </Button>
          <Button
            variant="custom"
            type="submit"
            onClick={() => {
              if (isObjectEmpty(errors)) closeDrawer();
              dispatch(setFilterCount(selectedFilters.length));
            }}
          >
            Применить
          </Button>
        </div>
      </form>
    </Drawer>
  );
};

export const EventLogFilter = connect(mapStateToProps)(EventLogFilterComponent);
