import Button, { ButtonProps } from '@mui/material/Button';
import React, { FC } from 'react';
import styles from './CustomButton.module.css';
import { ReactComponent as RefreshIcon } from '../../icons/Refresh.svg';
import clsx from 'clsx';

export const CustomButton: FC<
  Omit<ButtonProps, 'children'> & {
    loading: boolean;
    children: string;
    refreshIconClassName?: string;
  }
> = ({ loading, className, children, refreshIconClassName, startIcon, ...props }) => {
  return (
    <Button
      title={children}
      className={clsx(styles.button, className)}
      variant="custom"
      startIcon={startIcon}
      {...props}
    >
      {loading ? (
        <RefreshIcon
          className={clsx(styles['load-icon'], refreshIconClassName, {
            [styles['has-start-icon']]: !!startIcon,
          })}
        />
      ) : (
        <span className={clsx(styles.children, { [styles['has-start-icon']]: !!startIcon })}>
          {children}
        </span>
      )}
    </Button>
  );
};
