import { Resizable } from 're-resizable';
import React, { FC, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { throttle } from '../../helpers';
import { setClinentPanelWidth, TAppSlice } from '../../redux/appSlice';
import { RootState } from '../../redux/store';

type TResizablePanelProps = {
  isClientPanelOpen: TAppSlice['isClientPanelOpen'];
  clientPanelWidth: TAppSlice['clientPanelWidth'];
  isMobile: TAppSlice['isMobile'];
};

const mapStateToProps = (state: RootState) => ({
  isClientPanelOpen: state.app.isClientPanelOpen,
  clientPanelWidth: state.app.clientPanelWidth,
  isMobile: state.app.isMobile,
});

const ResizablePanelComponent: FC<TResizablePanelProps> = ({
  clientPanelWidth,
  isMobile,
  isClientPanelOpen,
  children,
}) => {
  const hidden = isMobile || isClientPanelOpen;
  const dispatch = useDispatch();
  const onResize = useCallback(
    throttle((width: number) => dispatch(setClinentPanelWidth(width)), 10),
    [],
  );
  return (
    <Resizable
      style={{
        position: 'absolute',
        right: 0,
        top: 0,
        pointerEvents: hidden ? 'none' : 'auto',
      }}
      defaultSize={{ width: clientPanelWidth, height: 0 }}
      enable={{
        top: false,
        right: false,
        bottom: false,
        left: true,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      minHeight={'100%'}
      onResize={(e, t, v) => onResize(v.offsetWidth)}
      minWidth={533}
      maxWidth={650}
    >
      {children}
    </Resizable>
  );
};

export const ResizablePanel = connect(mapStateToProps)(ResizablePanelComponent);
