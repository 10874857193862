import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import React, { FC } from 'react';
import styles from './WidgetColor.module.css';
import ColorPicker from './ColorPicker';
import { TEditAppicationInputs } from './applications/EditApplication';
import { BACKEND_URL, COPYRIGHT } from '../constants';
import { replaceJSX } from '../helpers';
import { useWatch, useFormContext } from 'react-hook-form';
import { CustomTypography } from './custom/CustomTypography';

type TWidgetColorComponent = {
  avatarSrc: string | null;
  coverSrc: string | null;
  showAvatar: boolean;
  hideHeader: boolean;
  hideFooter: boolean;
  name: string;
};

export const WidgetColor: FC<TWidgetColorComponent> = ({
  showAvatar,
  avatarSrc,
  name,
  coverSrc,
  hideHeader,
  hideFooter,
}) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<TEditAppicationInputs>();
  const widgetTitle = useWatch({ control, name: 'widget_title' });
  const buttonColor = useWatch({ control, name: 'widget_colors.button_color' });
  const fontColor = useWatch({ control, name: 'widget_colors.font_color' });
  const WIDGET_APP_NAME = name;

  return (
    <div className={styles['widget-color']}>
      <CustomTypography className={clsx('text-14', styles['color-title'])}>
        Заголовок виджета
      </CustomTypography>
      <TextField
        {...register('widget_title')}
        className="custom"
        fullWidth
        variant="standard"
        style={{ marginBottom: 32 }}
      />
      <div className={styles['flex-container']}>
        <div className={styles['set-colors-container']}>
          <div className={styles['set-color']}>
            <CustomTypography className={clsx('text-14', styles['color-title'])}>
              Цвет кнопок
            </CustomTypography>
            <ColorPicker componentName="widget_colors" colorName="button_color" />
            {errors.widget_colors?.button_color && (
              <CustomTypography color="error" className={'text-14'}>
                {errors.widget_colors?.button_color.message}
              </CustomTypography>
            )}
          </div>
          <div className={styles['set-color']}>
            <CustomTypography className={clsx('text-14', styles['color-title'])}>
              Цвет шрифта на кнопках
            </CustomTypography>
            <ColorPicker componentName="widget_colors" colorName="font_color" />
            {errors.widget_colors?.font_color && (
              <CustomTypography color="error" className={clsx('text-14')}>
                {errors.widget_colors?.font_color.message}
              </CustomTypography>
            )}
          </div>
          <div className={styles['set-color']}>
            <CustomTypography className={clsx('text-14', styles['color-title'])}>
              Цвет ссылок
            </CustomTypography>
            <ColorPicker componentName="widget_colors" colorName="link_color" />
            {errors.widget_colors?.link_color && (
              <CustomTypography color="error" className={clsx('text-14')}>
                {errors.widget_colors?.link_color.message}
              </CustomTypography>
            )}
          </div>
        </div>
        <div className={styles['widget-wrapper']}>
          <CustomTypography className={clsx('text-14', styles.preview)}>Превью:</CustomTypography>
          <div className={styles.widget}>
            <div style={{ padding: '0 24px' }}>
              {!hideHeader && (
                <CustomTypography className={styles['widget-title']}>
                  Авторизуйтесь пользователем Trusted
                </CustomTypography>
              )}
            </div>
            {coverSrc && (
              <img
                src={`${(coverSrc?.startsWith('data:') ? '' : BACKEND_URL + '/') + coverSrc}`}
                width="100%"
                style={{ display: 'block', marginBottom: 8 }}
              />
            )}
            <div style={{ padding: '0 24px' }}>
              {!coverSrc && <div className={styles.divider} />}
              <div className={styles['title-container']}>
                {showAvatar && avatarSrc && (
                  <img
                    src={`${(avatarSrc?.startsWith('data:') ? '' : BACKEND_URL + '/') + avatarSrc}`}
                    width="40px"
                    height="40px"
                    style={{ margin: '0 10px' }}
                  />
                )}
                <CustomTypography className={clsx(styles['widget-subtitle'])}>
                  {replaceJSX(
                    widgetTitle || '',
                    'WIDGET_APP_NAME',
                    <span style={{ color: buttonColor }}>{WIDGET_APP_NAME}</span>,
                  )}
                </CustomTypography>
              </div>
              <TextField
                label="Введите ID или логин"
                variant="standard"
                InputProps={{
                  classes: {
                    underline: styles['input-underline'],
                    focused: styles['widget-input-label-focused'],
                  },
                }}
                InputLabelProps={{
                  className: styles['widget-input-label'],
                  classes: {
                    focused: styles['widget-input-label-focused'],
                    asterisk: styles['disable-asterisk'],
                  },
                }}
                fullWidth
                className={styles['text-field']}
              />
              <div className={styles['widget-buttons']}>
                <Button style={{ color: buttonColor, fontWeight: 400 }}>СОЗДАТЬ АККАУНТ</Button>
                <Button
                  className={styles['widget-second-button']}
                  style={{
                    background: buttonColor,
                    color: fontColor,
                  }}
                >
                  ДАЛЕЕ
                </Button>
              </div>
              {!hideFooter && (
                <>
                  <div className={styles.divider}></div>

                  <div className={styles.footer}>
                    <CustomTypography
                      style={{ marginLeft: 'auto', fontSize: 12, width: 'fit-content' }}
                    >
                      {COPYRIGHT}
                    </CustomTypography>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
