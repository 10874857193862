import React, { FC, useEffect, useState } from 'react';
import styles from './PhoneProvider.module.css';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { isObjectEmpty } from '../../helpers';
import { CLIENT_ID } from '../../constants';
import clsx from 'clsx';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  MiscProviderType,
  TKloudParams,
  TMiscProvider,
  useCreateProviderMutation,
  useUpdateProviderMutation,
} from '../../redux/services/provider';
import { PasswordTextfield } from '../custom/PasswordTextfield';
import { ModalWithAction } from '../modal/ModalWithAction';
import { CustomTypography } from '../custom/CustomTypography';

export type TPhoneProvider = {
  id: string;
  avatar: string;
  params?: TKloudParams;
};

type TPhoneProviderProps = {
  isOpen: boolean;
  close: (value?: boolean) => void;
  phoneProvider?: TMiscProvider;
  // TODO: в phoneProvider принимать только необходимые параметры: issuer, external_client_id, external_client_secret, phoneProvider.id
};

export type PhoneProviderInputs = {
  issuer: string;
  external_client_id: string;
  external_client_secret: string;
  // phone_provider_enabled: boolean;
  // phone_number_required: boolean;
  is_public: boolean;
};

// const schema = yup.object({
//   phone_number_required: yup.boolean(),
//   issuer: yup
//     .string()
//     .url('Введите корректный url')
//     .when('phone_number_required', {
//       is: true,
//       then: yup.string().required('Обязательное поле'),
//     })
//     .when('phone_provider_enabled', {
//       is: true,
//       then: yup.string().required('Обязательное поле'),
//     }),
//   external_client_id: yup
//     .string()
//     .when('phone_number_required', {
//       is: true,
//       then: yup.string().required('Обязательное поле'),
//     })
//     .when('phone_provider_enabled', {
//       is: true,
//       then: yup.string().required('Обязательное поле'),
//     }),
//   external_client_secret: yup
//     .string()
//     .when('phone_number_required', {
//       is: true,
//       then: yup.string().required('Обязательное поле'),
//     })
//     .when('phone_provider_enabled', {
//       is: true,
//       then: yup.string().required('Обязательное поле'),
//     }),
// });

const schema = yup.object({
  issuer: yup
    .string()
    .url('Неверный формат ссылки')
    .min(1, 'Обязательное поле')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .nullable(true)
    .transform((v) => (typeof v === 'undefined' ? null : v)),
  external_client_id: yup
    .string()
    .max(255, 'Идентификатор не может превышать 255 символов')
    .required('Обязательное поле')
    .matches(/^[^\n ]*$/, {
      message: 'Идентификатор не может содержать пробелы',
    }),
  external_client_secret: yup
    .string()
    .max(255, 'Секретный ключ не может превышать 255 символов')
    .required('Обязательное поле')
    .matches(/^[^\n ]*$/, {
      message: 'Секретный ключ не может содержать пробелы',
    }),
});

export const PhoneProvider: FC<TPhoneProviderProps> = ({ isOpen, close, phoneProvider }) => {
  const methods = useForm<PhoneProviderInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { dirtyFields, errors },
    clearErrors,
    reset,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const closeSaveModal = () => setSaveModalOpen(false);
  const [createProvider, createResult] = useCreateProviderMutation();
  const [updateProvider, updateResult] = useUpdateProviderMutation();

  useEffect(() => {
    if (isOpen && phoneProvider) {
      setFields(phoneProvider);
    }
    return () => {
      reset();
    };
  }, [isOpen]);

  useEffect(() => {
    if (createResult.isSuccess || updateResult.isSuccess) close(true);
  }, [createResult, updateResult]);

  const setFields = async (provider?: Partial<TMiscProvider>) => {
    try {
      const { params, is_public } = provider || {};
      setValue('is_public', !!is_public);

      if (params) {
        (Object.keys(params) as Array<keyof TKloudParams>).forEach((field) => {
          setValue(field, (params as TKloudParams)?.[field] || '', { shouldDirty: !provider });
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<PhoneProviderInputs> = (data) => {
    if (phoneProvider) {
      updateProvider({
        body: {
          type: MiscProviderType.PHONE,
          provider_id: phoneProvider.id,
          ...data,
        },
        client_id: CLIENT_ID,
      });
    } else {
      createProvider({
        body: {
          type: MiscProviderType.PHONE,
          name: 'Номер телефона',
          is_active: true,
          ...data,
        },
        client_id: CLIENT_ID,
      });
    }
  };

  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) close();
    else setSaveModalOpen(true);
  };

  return (
    <Drawer
      classes={{ paper: styles['drawer-paper'] }}
      slotProps={{
        backdrop: { style: { background: 'none' } },
      }}
      onClose={handleClose}
      open={isOpen}
      anchor="right"
      variant="temporary"
    >
      <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
        <div className={styles.header}>
          <IconButton onClick={handleClose} className={styles['button-back']}>
            <ArrowTopIcon className={styles['arrow-icon']} />
          </IconButton>
          <CustomTypography className={clsx('text-24-medium', 'font-golos', styles.title)}>
            Настройки подтверждения номера телефона
          </CustomTypography>
          <IconButton className={styles['close-button']} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={styles['provider-form']}>
          <CustomTypography className={clsx('text-14', styles['input-title'], styles.asterisk)}>
            Базовый адрес авторизации (issuer)
          </CustomTypography>
          <TextField
            {...register('issuer', {
              onChange: () => {
                if (errors.issuer) {
                  clearErrors('issuer');
                }
              },
            })}
            className="custom"
            error={!!errors.issuer}
            helperText={errors.issuer ? errors.issuer.message : ''}
            fullWidth
            variant="standard"
            style={{ marginBottom: 32 }}
          />
          <CustomTypography className={clsx('text-14', styles['input-title'], styles.asterisk)}>
            Идентификатор ресурса (client_id)
          </CustomTypography>
          <TextField
            {...register('external_client_id', {
              onChange: () => {
                if (errors.external_client_id) {
                  clearErrors('external_client_id');
                }
              },
            })}
            className="custom"
            error={!!errors.external_client_id}
            helperText={errors.external_client_id ? errors.external_client_id.message : ''}
            fullWidth
            variant="standard"
            autoComplete="off"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Уникальный идентификатор подключаемого ресурса
          </CustomTypography>

          <CustomTypography className={clsx('text-14', styles['input-title'], styles.asterisk)}>
            Секретный ключ (Client_secret)
          </CustomTypography>
          <PasswordTextfield
            {...register('external_client_secret', {
              required: true,
              onChange: () => {
                if (errors.external_client_secret) clearErrors('external_client_secret');
              },
            })}
            className="custom"
            error={!!errors.external_client_secret}
            helperText={errors.external_client_secret ? errors.external_client_secret.message : ''}
            fullWidth
            variant="standard"
            autoComplete="off"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Секретный ключ ресурса
          </CustomTypography>
          <div className={styles['switch-wrapper']}>
            <CustomTypography className={clsx('text-14')}>
              Использовать для входа по коду
            </CustomTypography>
            <Controller
              control={control}
              name="is_public"
              defaultValue={false}
              render={({ field }) => (
                <Switch checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />
              )}
            />
          </div>
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Будут доступны в приложениях как способ входа с помощью одноразовых паролей
          </CustomTypography>
          <div className={styles['bottom-buttons']}>
            <Button onClick={handleClose} variant="custom" color="secondary">
              Отмена
            </Button>
            <Button
              style={{ marginLeft: 24 }}
              disabled={createResult.isLoading || updateResult.isLoading}
              variant="custom"
              type="submit"
            >
              Сохранить
            </Button>
          </div>
        </div>
      </form>

      <ModalWithAction
        title="Сохранение изменений"
        message="Изменения не сохранены. Продолжить без сохранения?"
        actionTitle="Продолжить"
        isOpen={saveModalOpen}
        onAction={() => {
          close();
          setSaveModalOpen(false);
        }}
        onClose={closeSaveModal}
      />
    </Drawer>
  );
};
