import { debounce, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import TextField from '@mui/material/TextField';
import React, { FC, useState, useCallback } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useFormContext, useWatch } from 'react-hook-form';
import styles from './ColorPicker.module.css';

const ColorPicker: FC<{
  componentName: string;
  colorName: string;
}> = ({ componentName, colorName }) => {
  const {
    register,
    control,
    clearErrors,
    setValue,
    formState: { errors },
  } = useFormContext();
  const setColorField = (color: string) =>
    setValue(`${componentName}.${colorName}`, color, { shouldDirty: true });
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  const value = useWatch({ control, name: `${componentName}.${colorName}` });
  const handleClickAway = () => setOpen(false);
  const setColorThrottle = useCallback(debounce(setColorField, 50), []);
  const theme = useTheme();

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        sx={{
          '& .input:hover': {
            color: theme.palette.custom.mainGrey,
          },
        }}
        className={styles.wrapper}
      >
        <div className={styles['flex-container']}>
          <div
            style={{ position: 'relative', background: value }}
            onClick={handleClick}
            className={styles['color-box']}
          />
          <TextField
            {...register(`${componentName}.${colorName}`, {
              onChange: () => {
                if ((errors?.[componentName] as never)?.[colorName])
                  clearErrors(`${componentName}.${colorName}`);
              },
            })}
            className={styles['text-field']}
            inputProps={{ className: 'input' }}
            InputProps={{
              className: 'input-root',
            }}
            variant="standard"
          />
        </div>
        {open ? (
          <div className={styles['color-picker']}>
            <HexColorPicker
              color={value}
              onChange={(color: string) => {
                setColorThrottle(color);
              }}
            />
            <div className="flex-container">
              <div style={{ background: value }} className="color-box" />
              <TextField
                className={styles['text-field']}
                inputProps={{ className: 'input' }}
                InputProps={{
                  className: 'input-root',
                }}
                {...register(`${componentName}.${colorName}`, {
                  onChange: () => {
                    if ((errors?.[componentName] as never)?.[colorName])
                      clearErrors(`${componentName}.${colorName}`);
                  },
                })}
                variant="standard"
              />
            </div>
          </div>
        ) : null}
      </Box>
    </ClickAwayListener>
  );
};

export default ColorPicker;
