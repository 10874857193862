import React, { FC } from 'react';
import styles from './CustomPopoverButton.module.css';
import { ButtonProps } from '@mui/material/Button';
import Button from '@mui/material/Button';
import clsx from 'clsx';

export const CustomPopoverButton: FC<ButtonProps> = ({ className, ...props }) => {
  return (
    <Button
      sx={(theme) => ({
        color: theme.palette.custom.main,
      })}
      {...props}
      className={clsx(styles['popover-button'], 'text-14', className)}
    />
  );
};
