import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BACKEND_URL } from '../../constants';
import { getObjectKeys } from '../../helpers';
import { getAccessToken } from '../../service/auth';

export type TLogger = {
  id: number;
  date: string;
  level: string;
  ip_address: string;
  device: string;
  user_id: number;
  client_id: string;
  event: string;
  description: string;
  name: string;
  avatar: string;
};

export const loggerApi = createApi({
  reducerPath: 'loggerApi',
  tagTypes: ['Logger'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}/api/logger/v1`,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getLog: builder.query<
      TLogger[],
      {
        user_id: string;
        log_type: string;
        start_date?: string;
        end_date?: string;
        search_string?: string;
        sort_by?: string;
        sort_direction?: string;
        number_of_records?: string;
        number_of_skip?: string;
        last_record_id?: string;
        search_param_level?: string;
        search_param_user_id?: string;
        search_param_client_id?: string;
        search_param_ip?: string;
        search_param_event?: string;
        search_param_description?: string;
      }
    >({
      query: (requestParams) => {
        let queryParams = '';

        getObjectKeys(requestParams)
          .slice(1)
          .forEach((paramKey) => {
            if (requestParams[paramKey] !== '')
              queryParams += paramKey + '=' + requestParams[paramKey] + '&';
          });

        return {
          url: `/${requestParams.user_id}?${queryParams}`,
        };
      },
    }),

    getLogEventsCount: builder.query<
      number,
      {
        user_id: string;
        log_type: string;
        search_string?: string;
        start_date?: string;
        end_date?: string;
        search_param_level?: string;
        search_param_user_id?: string;
        search_param_client_id?: string;
        search_param_ip?: string;
        search_param_event?: string;
        search_param_description?: string;
      }
    >({
      query: ({ user_id, ...rest }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const body = Object.fromEntries(Object.entries(rest).filter(([_, v]) => !!v));
        return {
          url: `/count/${user_id}`,
          body,
          method: 'POST',
        };
      },
    }),

    getUserActivity: builder.query<
      TLogger[],
      {
        user_id: string;
        log_type: string;
        start_date?: string;
        end_date?: string;
        search_string?: string;
        sort_by?: string;
        sort_direction?: string;
        number_of_records?: string;
        number_of_skip?: string;
        last_record_id?: string;
      }
    >({
      query: (requestParams) => {
        let queryParams = '';

        getObjectKeys(requestParams)
          .slice(1)
          .forEach((paramKey) => {
            if (requestParams[paramKey] !== '')
              queryParams += paramKey + '=' + requestParams[paramKey] + '&';
          });

        return {
          url: `/activity/${requestParams.user_id}?${queryParams}`,
        };
      },
    }),

    getUserEntriesCount: builder.query<
      number,
      {
        user_id: string;
        log_type: string;
        search_string?: string;
        start_date?: string;
        end_date?: string;
      }
    >({
      query: ({ user_id, ...rest }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const body = Object.fromEntries(Object.entries(rest).filter(([_, v]) => !!v));
        return {
          url: `/activity/count/${user_id}`,
          body,
          method: 'POST',
        };
      },
    }),
  }),
});

export const {
  useLazyGetLogQuery,
  useLazyGetLogEventsCountQuery,
  useLazyGetUserActivityQuery,
  useLazyGetUserEntriesCountQuery,
} = loggerApi;
