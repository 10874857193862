import * as jose from 'jose';
import { ACCESS_TOKEN_TTL, BACKEND_URL, CLIENT_ID, REFRESH_TOKEN_TTL } from '../constants';
import Cookies, { CookieSetOptions } from 'universal-cookie';
import { getTokensByRefreshToken } from '../requests/oidc';
import { TUserProfile } from '../redux/userSlice';

//ie 11.x uses msCrypto
declare global {
  interface Window {
    msCrypto: Crypto;
  }
}

const cookies = new Cookies();

export const getUrlParams = (): string => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get('code');

  return code || '';
};

export const decodeJWT = (
  token: string,
): (Omit<TUserProfile, 'id'> & { sub: string }) | undefined => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  } catch (e) {
    console.log('decodeJWT error: ' + e);
  }
};

export const isTokenValid = async (token: string): Promise<boolean> => {
  try {
    const JWKS = jose.createRemoteJWKSet(new URL(BACKEND_URL + '/api/oidc/jwks'));

    await jose.jwtVerify(token, JWKS, {
      audience: CLIENT_ID,
    });

    return true;
  } catch (e) {
    console.log('isTokenValid error: ' + e);
    return false;
  }
};

export const stringToArrayBuffer = (str: string): ArrayBuffer => {
  const encoder = new TextEncoder();
  return encoder.encode(str);
};

export const sha256 = async (str: string): Promise<ArrayBuffer> => {
  const buffer = stringToArrayBuffer(str);
  const hashBuffer = await crypto.subtle?.digest('SHA-256', buffer);
  return hashBuffer;
};

export const btoaRFC7636 = (buf: ArrayBuffer): string => {
  let binary = '';
  const bytes = new Uint8Array(buf);
  const length = bytes.byteLength;
  for (let i = 0; i < length; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
};

export const randomString = (): string => {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
  let str = '';
  const randomValues = Array.from(getCrypto().getRandomValues(new Uint8Array(43)));
  randomValues.forEach((v) => (str += charset[v % charset.length]));
  return str;
};

export const setCodeVerifier = (): string => {
  const codeVerifier = randomString();
  window.localStorage.setItem('codeVerifier', codeVerifier);
  return codeVerifier;
};

export const getCrypto = (): Crypto => {
  return window.crypto || window.msCrypto;
};

export const getAccessToken = async (): Promise<string | undefined> => {
  const accessToken = cookies.get('accessToken');
  const refreshToken = cookies.get('refreshToken');

  if (accessToken) return accessToken;

  if (refreshToken) {
    const { accessToken, idToken, expiresIn } = await getTokensByRefreshToken(refreshToken);
    if (await isTokenValid(idToken)) {
      setTokenCookies(accessToken, refreshToken, expiresIn);
      return accessToken;
    }
  }

  return undefined;
};

export const removeTokenCookies = (): void => {
  cookies.remove('accessToken');
  cookies.remove('refreshToken');
};

export const setTokenCookies = (
  accessToken: string,
  refreshToken: string,
  expiresIn: number,
): void => {
  accessToken && refreshToken && removeTokenCookies();

  const cookieAttributes: CookieSetOptions = {
    // sameSite: 'strict',
    path: '/',
  };

  const accessTokenExpiration = new Date();
  accessTokenExpiration.setSeconds(
    accessTokenExpiration.getSeconds() + expiresIn || ACCESS_TOKEN_TTL,
  );

  accessToken &&
    cookies.set('accessToken', accessToken, {
      ...cookieAttributes,
      expires: accessTokenExpiration,
    });

  const refreshTokenExpiration = new Date();
  refreshTokenExpiration.setSeconds(refreshTokenExpiration.getSeconds() + REFRESH_TOKEN_TTL);

  refreshToken &&
    cookies.set('refreshToken', refreshToken, {
      ...cookieAttributes,
      expires: refreshTokenExpiration,
    });
};
