import { BACKEND_URL } from '../constants';
import { TCustomFields, TUserProfile } from '../redux/userSlice';
import { getAccessToken } from '../service/auth';
import { sendLogoutRequest } from '../requests/oidc';

export enum PrivateScopesMode {
  SET_PRIVATE = 'SET_PRIVATE',
  SET_PUBLIC_OAUTH = 'SET_PUBLIC_OAUTH',
  SET_PUBLIC_GRAVATAR = 'SET_PUBLIC_GRAVATAR',
}

export interface ISettingsUser {
  profile_privacy: boolean;
  [key: string]: any;
}

export interface IPublicProfile extends TUserProfile {
  [key: string]: string | undefined | boolean | number | number[] | null | TCustomFields;
}

export const getUserRoleInApp = async (
  userId: string,
  clientId: string,
): Promise<TUserProfile['role']> => {
  try {
    const accessToken = await getAccessToken();

    const response = await fetch(BACKEND_URL + '/api/user/v1/role/' + userId + '/' + clientId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
    const { role } = await response.json();
    return role;
  } catch (e) {
    console.log('getUserRoleInApp error: ', e);
  }
};

export const checkAppsBeforeDelete = async (userId: string) => {
  try {
    const accessToken = await getAccessToken();
    const response = await fetch(`${BACKEND_URL}/api/user/v1/check_apps_before_delete/${userId}`, {
      headers: { Authorization: 'Bearer ' + accessToken },
    });
    const responseJson = response.json();
    return responseJson;
  } catch (e) {
    console.log('checkAppsBeforeDelete error: ', e);
  }
};

export const deleteUser = async (userId: string) => {
  try {
    const accessToken = await getAccessToken();
    const deleteResponse = await fetch(`${BACKEND_URL}/api/user/v1/${userId}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
      credentials: 'include',
    });
    if (deleteResponse.ok) sendLogoutRequest();
  } catch (e) {
    console.log('deleteUser error: ', e);
  }
};

export const checkCredentials = async (identifier: string, password: string) => {
  try {
    const body = 'identifier=' + identifier + '&password=' + password;
    let verified;

    const response = await fetch(`${BACKEND_URL}/api/auth/v1/check_credentials`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body,
    });

    if (response?.ok) ({ verified } = await response.json());

    return !!verified;
  } catch (e) {
    console.log('checkCredentials error: ', e);
  }
};

export const changePassword = async (newPassword: string, userId: string) => {
  try {
    const accessToken = await getAccessToken();
    await fetch(`${BACKEND_URL}/api/user/v1/password/${userId}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: `${encodeURIComponent('password')}=${encodeURIComponent(newPassword)}`,
    });
    sendLogoutRequest();
  } catch (e) {
    console.log('changePassword error: ', e);
  }
};

export const checkIdentifier = async (identifier: string) => {
  let isActive;
  try {
    const body = 'identifier=' + encodeURIComponent(identifier);

    const response = await fetch(BACKEND_URL + '/api/auth/v1/check_identifier', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded ',
      },
      method: 'POST',
      body,
    });
    if (response) ({ is_active: isActive } = await response.json());

    return !!isActive;
  } catch (e) {
    console.log('checkIdentifier error: ', e);
  }
};

export const getPublicProfile = async (userEmail: string): Promise<IPublicProfile | undefined> => {
  try {
    const accessToken = await getAccessToken();
    const response = await fetch(`${BACKEND_URL}/api/user/v1/profile?email=${encodeURIComponent(userEmail)}`, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });

    if (!response.ok) {
      console.log('getPublicProfile error: ', response.statusText);
    }

    return await response.json();
  } catch (e) {
    console.log('getPublicProfile error: ', e);
  }
};

export const getVCard = async (userEmail: string) => {
  try {
    const accessToken = await getAccessToken();
    const response = await fetch(`${BACKEND_URL}/api/user/v1/profile/vcard?email=${encodeURIComponent(userEmail)}`, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
    return await response.blob();
  } catch (e) {
    console.log('getVCard error: ', e);
  }
}

export const getSettingsUser = async (): Promise<ISettingsUser | undefined> => {
  try {
    const accessToken = await getAccessToken();
    const response = await fetch(`${BACKEND_URL}/api/user/v1/settings`, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
    return await response.json();
  } catch (e) {
    console.log('getSettingsUser error: ', e);
  }
}

export const setSettingsUser = async (settings: Partial<ISettingsUser>) => {
  try {
    const accessToken = await getAccessToken();

    // Преобразование объекта settings в JSON
    const body = JSON.stringify({
      profile_privacy: settings.profile_privacy,
    });

    await fetch(`${BACKEND_URL}/api/user/v1/settings`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
      },
      body,
    });
  } catch (e) {
    console.log('setSettingsUser error: ', e);
  }
}
