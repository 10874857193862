import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TNotice = {
  error?: string;
  id: number;
  isRead: boolean;
  message: string;
  statusCode?: number;
  timestamp: string;
  avatar?: string | null;
};

export type TNoticesSlice = {
  notices: TNotice[];
};

const initialState: TNoticesSlice = {
  notices: [],
};

const noticesSlice = createSlice({
  name: 'noticesSlice',
  initialState,
  reducers: {
    setNotice(state, action: PayloadAction<TNotice>) {
      state.notices = [action.payload, ...state.notices];
    },
    deleteNotice(state, action: PayloadAction<number>) {
      state.notices = state.notices.filter((notices) => notices.id !== action.payload);
    },
    deleteAllNotices(state) {
      state.notices = [];
    },
    setIsReadNotice(state, action: PayloadAction<boolean>) {
      state.notices.forEach((notices) => (notices.isRead = action.payload));
    },
  },
});

export const { setNotice, deleteNotice, deleteAllNotices, setIsReadNotice } = noticesSlice.actions;
export default noticesSlice.reducer;
