import React, { useLayoutEffect } from 'react';
import { Outlet } from 'react-router-dom-v5-compat';
import { showDisplay } from '../../helpers';

export const PublicRoute: React.FC = () => {
  useLayoutEffect(() => {
    showDisplay();
  }, []);

  return <Outlet />;
};
