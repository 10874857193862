import Drawer from '@mui/material/Drawer';
import styles from './EditRuleValidation.module.css';
import React, { FC, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import clsx from 'clsx';
import {
  TCreateRuleValidation,
  TRuleValidation,
  useCreateRuleValidationMutation,
  useUpdateRuleValidationMutation,
} from '../../redux/services/settings';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import { CustomTypography } from '../custom/CustomTypography';

export type TEditRuleValidation = {
  isOpen: boolean;
  close: () => void;
  selectedRuleValidation?: TRuleValidation;
};

export const EditRuleValidation: FC<TEditRuleValidation> = ({
  isOpen,
  close,
  selectedRuleValidation,
}) => {
  const [isSave, setIsSave] = useState(false);

  const [saveTitle, setTitle] = useState<string>('');
  const [saveError, setError] = useState<string>('');
  const [saveRegex, setRegex] = useState<string>('');
  const [saveActive, setActive] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<TCreateRuleValidation>({
    title: '',
    error: '',
    regex: '',
    active: false,
  });

  const [createRuleValidation] = useCreateRuleValidationMutation();
  const [updateRuleValidation] = useUpdateRuleValidationMutation();

  const handleDrawerClose = () => {
    setTitle('');
    setError('');
    setRegex('');
    setActive(false);
    setInitialData({
      title: '',
      error: '',
      regex: '',
      active: false,
    });
    close();
  };

  const headerText = selectedRuleValidation
    ? 'Редактировать правило валидации'
    : 'Создать правило валидации';

  useEffect(() => {
    if (selectedRuleValidation) {
      const initial = {
        title: selectedRuleValidation.title,
        error: selectedRuleValidation.error,
        regex: selectedRuleValidation.regex,
        active: selectedRuleValidation.active,
      };

      setInitialData(initial);
      setTitle(initial.title);
      setError(initial.error);
      setRegex(initial.regex);
      setActive(initial.active);
    } else {
      const initial = {
        title: '',
        error: '',
        regex: '',
        active: false,
      };

      setInitialData(initial);
      setTitle(initial.title);
      setError(initial.error);
      setRegex(initial.regex);
      setActive(initial.active);
    }
  }, [selectedRuleValidation]);

  useEffect(() => {
    const isDataChanged =
      saveTitle !== initialData.title ||
      saveError !== initialData.error ||
      saveRegex !== initialData.regex ||
      saveActive !== initialData.active;

    setIsSave(isDataChanged);
  }, [saveTitle, saveError, saveRegex, saveActive, initialData]);

  const handleSave = async () => {
    try {
      if (selectedRuleValidation) {
        await updateRuleValidation({
          id: selectedRuleValidation.id,
          body: {
            title: saveTitle !== initialData.title ? saveTitle : undefined,
            error: saveError !== initialData.error ? saveError : undefined,
            regex: saveRegex !== initialData.regex ? saveRegex : undefined,
            active: saveActive !== initialData.active ? saveActive : undefined,
          },
        }).unwrap();
      } else {
        await createRuleValidation({
          title: saveTitle,
          error: saveError,
          regex: saveRegex,
          active: saveActive,
        }).unwrap();
      }

      handleDrawerClose();
    } catch (error) {
      // empty_line
    }
  };

  return (
    <Drawer
      onClose={() => handleDrawerClose()}
      open={isOpen}
      anchor="right"
      variant="temporary"
      classes={{ paper: styles['drawer-paper'] }}
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <CustomTypography className={clsx('text-24-medium', 'font-golos')}>
            {headerText}
          </CustomTypography>
          <IconButton onClick={handleDrawerClose} className={styles['close-button']}>
            <CloseIcon />
          </IconButton>
        </div>
        <CustomTypography color="grey" className={clsx('text-12', styles.description)}>
          Выберите правила валидации, которые хотите применить для поля:
        </CustomTypography>
        <div className={styles.content}>
          <CustomTypography className={styles.header3}>Название</CustomTypography>
          <input
            className={styles.input}
            placeholder="Введите название"
            value={saveTitle}
            onChange={(event) => setTitle(event.target.value)}
          />
          <CustomTypography color="grey" className={clsx('text-12', styles.description)}>
            Название поля в системе
          </CustomTypography>
          <CustomTypography className={styles.header3}>Текст ошибки</CustomTypography>
          <input
            className={styles.input}
            placeholder="Введите текст ошибки"
            value={saveError}
            onChange={(event) => setError(event.target.value)}
          />
          <CustomTypography color="grey" className={clsx('text-12', styles.description)}>
            Тест ошибки, если сработает правило валидации
          </CustomTypography>
          <CustomTypography className={styles.header3}>Регулярное выражение</CustomTypography>
          <input
            className={styles.input}
            placeholder="Введите регулярное выражение"
            value={saveRegex}
            onChange={(event) => setRegex(event.target.value)}
          />
          <CustomTypography color="grey" className={clsx('text-12', styles.description)}>
            Регулярное выражение, описывающее правило валидации в формате ^\S+$
          </CustomTypography>
          <div className={styles.switchers}>
            <div className={styles.switcher}>
              <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
                Активность
              </CustomTypography>
              <Switch
                className={styles.switch}
                checked={saveActive}
                onChange={() => setActive(!saveActive)}
              />
            </div>
          </div>
          <div className={styles['bottom-buttons']}>
            <Button variant="custom" color="secondary" onClick={handleDrawerClose}>
              Отмена
            </Button>
            <Button
              style={{ marginLeft: 24 }}
              onClick={handleSave}
              disabled={!isSave}
              variant="custom"
              type="submit"
            >
              Сохранить
            </Button>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
