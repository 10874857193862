import React, { FC, useEffect, useState } from 'react';
import styles from './PhoneProvider.module.css';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { isObjectEmpty } from '../../helpers';
import { CLIENT_ID } from '../../constants';
import clsx from 'clsx';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  MiscProviderType,
  TEmailParams,
  TMiscProvider,
  useCreateProviderMutation,
  useUpdateProviderMutation,
} from '../../redux/services/provider';
import { PasswordTextfield } from '../custom/PasswordTextfield';
import { ModalWithAction } from '../modal/ModalWithAction';
import { CustomTypography } from '../custom/CustomTypography';

export type TEmailProvider = {
  id: string;
  avatar: string;
  params?: TEmailParams;
};

type TEmailProviderProps = {
  isOpen: boolean;
  close: (value?: boolean) => void;
  emailProvider?: TMiscProvider;
  // TODO: в emailProvider принимать только необходимые параметры
};

export type EmailProviderInputs = {
  root_mail: string;
  mail_hostname: string;
  mail_port: string;
  mail_password: string;
  mail_code_ttl_sec: string;
  is_public: boolean;
};

const schema = yup.object({
  root_mail: yup
    .string()
    .required('Обязательное поле')
    .max(255, 'Адрес электронной почты не может превышать 255 символов')
    .email('Неверный формат адреса электронной почты'),
  mail_hostname: yup
    .string()
    .max(255, 'Адрес сервера исходящей почты не может превышать 2000 символов')
    .required('Обязательное поле'),
  mail_port: yup
    .string()
    .required('Обязательное поле')
    .min(1, 'Порт сервера исходящей почты не может быть меньше 1 символа')
    .max(5, 'Порт сервера исходящей почты не может превышать 5 символов')
    .matches(/^[^\n ]*$/, {
      message: 'Порт сервера исходящей почты не может содержать пробелы',
    }),
  mail_password: yup.string().required('Обязательное поле'),
  mail_code_ttl_sec: yup
    .string()
    .required('Обязательное поле')
    .matches(/^[^\n ]*$/, {
      message: 'Время жизни кода подтверждения не может содержать пробелы',
    }),
});

export const EmailProvider: FC<TEmailProviderProps> = ({ isOpen, close, emailProvider }) => {
  const methods = useForm<EmailProviderInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { dirtyFields, errors },
    clearErrors,
    reset,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const closeSaveModal = () => setSaveModalOpen(false);
  const [createProvider, createResult] = useCreateProviderMutation();
  const [updateProvider, updateResult] = useUpdateProviderMutation();

  useEffect(() => {
    if (isOpen && emailProvider) {
      setFields(emailProvider);
    }
    return () => {
      reset();
    };
  }, [isOpen]);

  useEffect(() => {
    if (createResult.isSuccess || updateResult.isSuccess) close(true);
  }, [createResult, updateResult]);

  const setFields = async (provider?: Partial<TMiscProvider>) => {
    try {
      const { params, is_public } = provider || {};
      setValue('is_public', !!is_public);
      if (params) {
        (Object.keys(params) as Array<keyof TEmailParams>).forEach((field) => {
          setValue(field, (params as TEmailParams)?.[field] || '', { shouldDirty: !provider });
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<EmailProviderInputs> = (data) => {
    if (emailProvider) {
      updateProvider({
        body: {
          type: MiscProviderType.EMAIL,
          provider_id: emailProvider.id,
          ...data,
        },
        client_id: CLIENT_ID,
      });
    } else {
      createProvider({
        body: {
          type: MiscProviderType.EMAIL,
          name: 'Электронная почта',
          ...data,
          is_active: true,
        },
        client_id: CLIENT_ID,
      });
    }
  };

  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) close();
    else setSaveModalOpen(true);
  };

  return (
    <Drawer
      classes={{ paper: styles['drawer-paper'] }}
      slotProps={{
        backdrop: { style: { background: 'none' } },
      }}
      onClose={handleClose}
      open={isOpen}
      anchor="right"
      variant="temporary"
    >
      <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
        <div className={styles.header}>
          <IconButton onClick={handleClose} className={styles['button-back']}>
            <ArrowTopIcon className={styles['arrow-icon']} />
          </IconButton>
          <CustomTypography className={clsx('text-24-medium', 'font-golos', styles.title)}>
            Настройки почты
          </CustomTypography>
          <IconButton className={styles['close-button']} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={styles['provider-form']}>
          <CustomTypography className={clsx('text-14', styles['input-title'], styles.asterisk)}>
            Основной почтовый адреc
          </CustomTypography>
          <TextField
            {...register('root_mail', {
              onChange: () => {
                if (errors.root_mail) {
                  clearErrors('root_mail');
                }
              },
            })}
            className="custom"
            error={!!errors.root_mail}
            helperText={errors.root_mail ? errors.root_mail.message : ''}
            fullWidth
            variant="standard"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Адрес почтовой службы, который также будет использоваться для рассылки писем
          </CustomTypography>

          <CustomTypography className={clsx('text-14', styles['input-title'], styles.asterisk)}>
            Адрес сервера исходящей почты
          </CustomTypography>
          <TextField
            {...register('mail_hostname', {
              onChange: () => {
                if (errors.mail_hostname) {
                  clearErrors('mail_hostname');
                }
              },
            })}
            className="custom"
            error={!!errors.mail_hostname}
            helperText={errors.mail_hostname ? errors.mail_hostname.message : ''}
            fullWidth
            variant="standard"
            autoComplete="off"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Адрес почтовой службы для рассылки писем
          </CustomTypography>

          <CustomTypography className={clsx('text-14', styles['input-title'], styles.asterisk)}>
            Порт сервера исходящей почты
          </CustomTypography>
          <TextField
            {...register('mail_port', {
              onChange: () => {
                if (errors.mail_port) {
                  clearErrors('mail_port');
                }
              },
            })}
            className="custom"
            error={!!errors.mail_port}
            helperText={errors.mail_port ? errors.mail_port.message : ''}
            fullWidth
            variant="standard"
            autoComplete="off"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Порт почтовой службы для рассылки писем
          </CustomTypography>

          <CustomTypography className={clsx('text-14', styles['input-title'], styles.asterisk)}>
            Пароль почты
          </CustomTypography>
          <PasswordTextfield
            {...register('mail_password', {
              required: true,
              onChange: () => {
                if (errors.mail_password) clearErrors('mail_password');
              },
            })}
            className="custom"
            error={!!errors.mail_password}
            helperText={errors.mail_password ? errors.mail_password.message : ''}
            fullWidth
            variant="standard"
            autoComplete="off"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Обычный пароль или пароль приложения, который создается в настройках аккаунта
            <br />
            почтового сервиса
          </CustomTypography>

          <CustomTypography className={clsx('text-14', styles['input-title'], styles.asterisk)}>
            Время жизни кода подтверждения
          </CustomTypography>
          <TextField
            {...register('mail_code_ttl_sec', {
              onChange: () => {
                if (errors.mail_port) {
                  clearErrors('mail_code_ttl_sec');
                }
              },
            })}
            className="custom"
            error={!!errors.mail_code_ttl_sec}
            helperText={errors.mail_code_ttl_sec ? errors.mail_code_ttl_sec.message : ''}
            fullWidth
            variant="standard"
            autoComplete="off"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Время жизни кодов подтверждения адреса электронной почты в секундах
          </CustomTypography>

          <div className={styles['switch-wrapper']}>
            <CustomTypography className={clsx('text-14')}>
              Использовать для входа по коду
            </CustomTypography>
            <Controller
              control={control}
              name="is_public"
              defaultValue={false}
              render={({ field }) => (
                <Switch checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />
              )}
            />
          </div>
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Будут доступны в приложениях как способ входа с помощью одноразовых паролей
          </CustomTypography>
          <div className={styles['bottom-buttons']}>
            <Button onClick={handleClose} variant="custom" color="secondary">
              Отмена
            </Button>
            <Button
              style={{ marginLeft: 24 }}
              disabled={createResult.isLoading || updateResult.isLoading}
              variant="custom"
              type="submit"
            >
              Сохранить
            </Button>
          </div>
        </div>
      </form>

      <ModalWithAction
        title="Сохранение изменений"
        message="Изменения не сохранены. Продолжить без сохранения?"
        actionTitle="Продолжить"
        isOpen={saveModalOpen}
        onAction={() => {
          close();
          setSaveModalOpen(false);
        }}
        onClose={closeSaveModal}
      />
    </Drawer>
  );
};
