import Reference from 'yup/lib/Reference';
import * as yup from 'yup';

declare module 'yup' {
  interface StringSchema {
    equalTo(ref: Reference<unknown>, msg: string): StringSchema;
  }
}

function equalTo(ref: Reference<unknown>, msg: string) {
  return this.test({
    name: 'equalTo',
    exclusive: false,
    message: msg || '${path} must be the same as ${reference}',
    params: {
      reference: ref.path,
    },
    test: function (value: string) {
      return value === this.resolve(ref);
    },
  });
}

yup.addMethod(yup.string, 'equalTo', equalTo);

export default yup;
